import { Card } from 'components/Card/Card'
import { Spinner } from 'components/Spinner/Spinner'
import { useState } from 'react'

import { ActionMenu } from 'components/ActionMenu/ActionMenu'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { MainstayModal } from 'components/Modal/Modal'
import { Pill } from 'components/Pill/Pill'
import { TextArea } from 'components/TextArea/TextArea'
import { TextInput } from 'components/TextInput/TextInput'
import moment from 'moment'
import 'page/knowledge-base/KnowledgeSource/KnowledgeSourcesTable.scss'
import { PERMISSIONS } from 'util/permissions/permissions'

import {
  GenAIScapeKnowledgeSourceType,
  GenAIScrapeAttemptStatus,
  GenAIScrapeKnowledgeSourceShapeType,
} from 'api'

export function getKnowledgeSourceTitle(
  knowledgeSource: GenAIScrapeKnowledgeSourceShapeType
): string {
  const typeForTitle =
    knowledgeSource.source_type === GenAIScapeKnowledgeSourceType.URL
      ? 'Website'
      : 'Document'
  return (
    knowledgeSource.title || `${typeForTitle}: ${knowledgeSource.origin_url}`
  )
}

const EditKnowledgeSourceModal = ({
  open,
  setOpen,
  knowledgeSource,
  editKnowledgeSource,
}: {
  readonly open: boolean
  readonly setOpen: (open: boolean) => void
  readonly knowledgeSource: GenAIScrapeKnowledgeSourceShapeType
  readonly editKnowledgeSource: (
    knowledgeSource: GenAIScrapeKnowledgeSourceShapeType
  ) => Promise<void>
}) => {
  const [title, setTitle] = useState(knowledgeSource.title || '')
  const [description, setDescription] = useState(
    knowledgeSource.description || ''
  )

  const [editLoading, setEditLoading] = useState(false)

  const onEditSubmit = async () => {
    setEditLoading(true)

    try {
      await editKnowledgeSource({
        ...knowledgeSource,
        title: title.length > 0 ? title : null,
        description: description.length > 0 ? description : null,
      })
    } finally {
      setEditLoading(false)
    }

    onCancel()
  }

  const onCancel = () => {
    setTitle(knowledgeSource.title || '')
    setDescription(knowledgeSource.description || '')
    setOpen(false)
  }

  return (
    <MainstayModal
      show={open}
      text="Edit Knowledge Source"
      onClose={() => setOpen(!open)}
      onSubmit={onEditSubmit}
      disableSubmit={editLoading}
      loading={editLoading}>
      <p>
        The source type and URL cannot be modified. If you wish to change these
        values then archive this knowledge source and make a new one.
      </p>
      <div>
        <span>Title</span>
        <TextInput value={title} onChange={e => setTitle(e.target.value)} />
      </div>
      <div className="my-3">
        <span>Description</span>
        <TextArea
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
      </div>
    </MainstayModal>
  )
}

export const KnowledgeSourcesTable = ({
  knowledgeSource,
  editKnowledgeSource,
  archiveKnowledgeSource,
  triggerScrape,
}: {
  readonly knowledgeSource: GenAIScrapeKnowledgeSourceShapeType
  readonly editKnowledgeSource: (
    knowledgeSource: GenAIScrapeKnowledgeSourceShapeType
  ) => Promise<void>
  readonly archiveKnowledgeSource: () => Promise<void>
  readonly triggerScrape: () => Promise<void>
}) => {
  const [showingEditModal, setShowingEditModal] = useState(false)

  const [archiveLoading, setArchiveLoading] = useState(false)
  const [triggerScrapeLoading, setTriggerScrapeLoading] = useState(false)

  const onArchive = async () => {
    setArchiveLoading(true)
    try {
      await archiveKnowledgeSource()
    } finally {
      setArchiveLoading(false)
    }
  }

  const onTriggerScrape = async () => {
    setTriggerScrapeLoading(true)
    try {
      await triggerScrape()
    } finally {
      setTriggerScrapeLoading(false)
    }
  }

  return (
    <>
      <EditKnowledgeSourceModal
        open={showingEditModal}
        setOpen={setShowingEditModal}
        knowledgeSource={knowledgeSource}
        editKnowledgeSource={editKnowledgeSource}
      />

      <Card
        className="knowledge-base-scraping-knowledge-source mt-2"
        title={
          <div className="d-flex flex-row w-100 justify-content-between">
            <h5 className="text-mainstay-dark-blue">
              {getKnowledgeSourceTitle(knowledgeSource)}
            </h5>
            <ActionMenu
              popoverPlacement="bottom-end"
              className="mt-2"
              stopPropagation={true}
              menuItems={[
                {
                  label: 'Edit',
                  icon: <AHIcon name="edit" />,
                  onSelect: () => setShowingEditModal(true),
                  permission: PERMISSIONS.KNOWLEDGE_SOURCE.EDIT,
                },
                {
                  label: 'Scrape',
                  icon: (
                    <>
                      {triggerScrapeLoading ? (
                        <Spinner
                          size="sm"
                          className="ml-1 mr-2 stroke-mainstay-dark-blue"
                        />
                      ) : (
                        <AHIcon name="cloud_download" />
                      )}
                    </>
                  ),
                  onSelect: onTriggerScrape,
                },
                {
                  label: 'Delete',
                  icon: (
                    <>
                      {archiveLoading ? (
                        <Spinner
                          size="sm"
                          className="ml-1 stroke-mainstay-dark-blue"
                        />
                      ) : (
                        <AHIcon name="delete" />
                      )}
                    </>
                  ),
                  onSelect: onArchive,
                  permission: PERMISSIONS.KNOWLEDGE_SOURCE.DELETE,
                },
              ]}
            />
          </div>
        }>
        <table>
          <tbody>
            <tr>
              <th>URL</th>
              <td className="pl-2">
                <a
                  href={knowledgeSource.origin_url}
                  target="_blank"
                  className="m-0">
                  {knowledgeSource.origin_url}
                </a>
              </td>
            </tr>
            <tr>
              <th>Last Scraped</th>
              <td className="pl-2">
                {knowledgeSource.scrape_date
                  ? moment(knowledgeSource.scrape_date).format(
                      'YYYY-MM-DD hh:mm A'
                    )
                  : 'Never'}
              </td>
            </tr>
            <tr>
              <th>Status</th>
              <td className="pl-2 pt-1 d-flex align-items-center">
                <Pill
                  className="w-content"
                  text={knowledgeSource.latest_scrape_status || 'UNKNOWN'}
                  color={getColorForScrapeStatus(
                    knowledgeSource.latest_scrape_status
                  )}
                />
                {knowledgeSource.latest_scrape_status ===
                  GenAIScrapeAttemptStatus.FAIL && (
                  <span className="pl-2 text-mainstay-spark-red small">
                    {knowledgeSource.fail_reason}
                  </span>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </Card>
    </>
  )
}

export function getColorForScrapeStatus(status: string | null) {
  if (status === GenAIScrapeAttemptStatus.QUEUED) {
    return 'mainstay-dark-blue-50'
  }
  if (status === GenAIScrapeAttemptStatus.STARTED) {
    return 'mainstay-blue-70'
  }
  if (status === GenAIScrapeAttemptStatus.SUCCESS) {
    return 'mainstay-success-500'
  }
  return 'mainstay-spark-red'
}

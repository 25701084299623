import AdmithubOnly from 'components/AdmithubOnly/AdmithubOnly'
import Select from 'components/Select/SelectV2'
import NoMatch from 'page/NoMatch'
import 'page/dashboard/DashboardPage.scss'
import { InternalToolsPageContainer } from 'page/internal-tools/InternalToolsPageContainer'
import React from 'react'

import { http2 } from 'api/http'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { isRight } from 'fp-ts/lib/Either'
import * as t from 'io-ts'
import * as et from 'io-ts/Type'
import { sortBy } from 'lodash'
import {
  MainstayFlexTableCol,
  MainstayFlexTableHeaderCol,
} from 'mainstay-ui-kit/MainstayFlexCol/MainstayFlexCol'
import {
  MainstayFlexTableHeader,
  MainstayFlexTableRow,
} from 'mainstay-ui-kit/MainstayFlexRow/MainstayFlexRow'
import { MainstayFlexTable } from 'mainstay-ui-kit/MainstayFlexTable/MainstayFlexTable'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import 'page/webchat/SettingsWebChatForm/BotBadge/BotBadge.scss'
import { useSelector } from 'util/hooks'
import { Link } from 'util/routing'
import { ButtonIconRound } from 'components/ButtonIconRound/ButtonIconRound'
import { SearchInput } from 'components/SearchInput/SearchInput'
import {
  getCanAuditUsers,
  getInstitutionId,
} from 'store/triage/profile/selectors'
import { Button } from 'components/Button/Button'
import { ConfirmationModal } from 'components/Modal/Modal'
import { getAuthedUserEmail } from 'store/auth/selectors'

const userShape = t.type({
  id: t.string,
  email: et.nullable(t.string),
  institutions: t.array(t.string),
})
type UserType = t.TypeOf<typeof userShape>
const getAllMainstayUsers = () =>
  http2({
    url: 'api/v0/mascot-internal-tools/audit-user-access/list-all-users/',
    method: 'GET',
    shape: t.type({ users: t.array(userShape) }),
  })

const institutionShape = t.type({
  id: t.string,
  displayName: t.string,
  abbr: et.nullable(t.string),
  oliName: t.string,
})
type InstitutionType = t.TypeOf<typeof institutionShape>
const getAllInstitutions = () =>
  http2({
    url:
      'api/v0/mascot-internal-tools/audit-user-access/list-all-institutions/',
    method: 'GET',
    shape: t.type({ institutions: t.array(institutionShape) }),
  })
export const addToInstitution = (institutionId: string, email: string | null) =>
  http2({
    url: 'api/v0/mascot-internal-tools/audit-user-access/add-to-institution/',
    method: 'POST',
    data: { institution_ids: [institutionId], email },
    shape: userShape,
  })
const removeFromInstitution = (institutionId: string, email: string | null) =>
  http2({
    url:
      'api/v0/mascot-internal-tools/audit-user-access/remove-from-institution/',
    method: 'POST',
    data: { institution_ids: [institutionId], email },
    shape: userShape,
  })
const removeFromAllInstitutions = (email: string) =>
  http2({
    url:
      'api/v0/mascot-internal-tools/audit-user-access/remove-from-all-institutions/',
    method: 'POST',
    data: { email },
    shape: userShape,
  })

interface IOption {
  label: string
  value: string
}

function formatCollegeName(college: InstitutionType): string {
  return college.displayName + ' - ' + college.oliName + ' - ' + college.id
}

const AuditPageContainer = ({ children }: { children: React.ReactNode }) => {
  return (
    <AdmithubOnly allowStaffOnly fallback={<NoMatch />}>
      <InternalToolsPageContainer>
        <div className="px-4 pt-4 text-mainstay-dark-blue-80">
          <h2>Audit User Access</h2>
          {children}
        </div>
      </InternalToolsPageContainer>
    </AdmithubOnly>
  )
}

const AuditUserAccess = () => {
  // Users data
  const currentUserEmail = useSelector(getAuthedUserEmail)
  const currentInstitution = useSelector(getInstitutionId)
  const [users, setUsers] = React.useState<ReadonlyArray<UserType>>([])
  const [
    showDeleteConfirmationModal,
    setShowDeleteConfirmationModal,
  ] = React.useState(false)
  const [selectedUserId, setSelectedUserId] = React.useState('')
  const selectedUser = users.find(u => u.id === selectedUserId)
  const userOptions = sortBy(users, x =>
    (x.email ?? '').toLowerCase()
  ).map(u => ({ label: u.email ?? 'UNKNOWN', value: u.id }))

  React.useEffect(() => {
    getAllMainstayUsers().then(res => {
      if (isRight(res)) {
        setUsers(res.right.users)
      } else {
        toast.error('Unable to fetch users')
      }
    })
  }, [])

  // Institutions data
  const [institutions, setInsitutions] = React.useState<
    ReadonlyArray<InstitutionType>
  >([])
  const [institutionToAdd, setInstitutionToAdd] = React.useState('')
  const [institutionSearchQuery, setInstitutionSearchQuery] = React.useState('')
  const institutionOptions = sortBy(
    institutions.filter(
      inst => !(selectedUser?.institutions ?? []).includes(inst.id) // only display institutions that the user isn't already a part of
    ),
    x => formatCollegeName(x).toLowerCase()
  ).map(x => ({
    value: x.id,
    label: formatCollegeName(x),
  }))

  React.useEffect(() => {
    getAllInstitutions().then(res => {
      if (isRight(res)) {
        setInsitutions(res.right.institutions)
      } else {
        toast.error('Unable to fetch users')
      }
    })
  }, [])

  const canAuditUsers = useSelector(getCanAuditUsers)
  if (!canAuditUsers) {
    return (
      <AuditPageContainer>
        You do not have permission to access this page.
      </AuditPageContainer>
    )
  }

  return (
    <AuditPageContainer>
      <Select<IOption>
        key="userToUpdate"
        className="form-control p-0 border-0 min-width-200px max-width-500 mt-3"
        classNamePrefix="react-select"
        name="userPicker"
        placeholder="Select user"
        value={userOptions.find(u => u.value === selectedUserId)}
        isLoading={userOptions.length === 0}
        onChange={option => {
          if (option && !Array.isArray(option)) {
            setSelectedUserId(option.value)
          }
        }}
        options={userOptions}
        closeMenuOnSelect={true}
      />
      {selectedUser && (
        <>
          <ConfirmationModal
            title="Remove access from all"
            helpText={`Are you sure you want to remove ${selectedUser.email ??
              'user'}'s access from all organizations?`}
            onClose={() => setShowDeleteConfirmationModal(false)}
            show={showDeleteConfirmationModal}
            confirmButtonText="Yes, remove"
            onConfirm={() => {
              if (selectedUser.email) {
                removeFromAllInstitutions(selectedUser.email).then(res => {
                  if (isRight(res)) {
                    setUsers(prev => [
                      ...prev.filter(user => user.email !== res.right.email),
                      res.right,
                    ])
                    toast.info(`Removed the user from all organizations`)
                  } else {
                    toast.error('Unable to remove user from all organization')
                  }
                  setInstitutionToAdd('')
                })
              }
              setShowDeleteConfirmationModal(false)
            }}
            hideCheckbox={true}
          />
          <h5 className="pt-4">Add user to organization</h5>
          <div className="d-flex align-items-center mt-2">
            <Select<IOption>
              key={`institutionToAdd_${institutionToAdd}`}
              className="form-control p-0 border-0 min-width-200px max-width-500"
              classNamePrefix="react-select"
              name="institutionPicker"
              placeholder="Select organization"
              value={institutionOptions.find(
                institution => institution.value === institutionToAdd
              )}
              isLoading={institutions.length === 0}
              onChange={option => {
                if (option && !Array.isArray(option) && option.value) {
                  setInstitutionToAdd(option.value)
                }
              }}
              options={institutionOptions}
              closeMenuOnSelect={true}
            />
            {institutionToAdd && (
              <div
                onClick={() =>
                  addToInstitution(institutionToAdd, selectedUser.email).then(
                    res => {
                      if (isRight(res)) {
                        setUsers(prev => [
                          ...prev.filter(
                            user => user.email !== res.right.email
                          ),
                          res.right,
                        ])
                        toast.info(`Added the user to ${institutionToAdd}`)
                      } else {
                        toast.error('Unable to remove user from organization')
                      }
                      setInstitutionToAdd('')
                    }
                  )
                }
                className="text-secondary-teal ml-2 d-flex align-items-center">
                <AHIcon className="pointer" name="add_circle_outline" />
                <div className="mt-1 pointer">Add to organization</div>
              </div>
            )}
          </div>
          <Button
            className="bg-transparent text-mainstay-spark-red px-0  mb-4 mt-2"
            outlined
            onClick={() => setShowDeleteConfirmationModal(true)}>
            {' '}
            Remove access from all organizations{' '}
          </Button>
          <MainstayFlexTable className="mt-5">
            <SearchInput
              className="w-360 z-index-unset my-3"
              onChange={e => {
                setInstitutionSearchQuery(e.target.value)
              }}
              value={institutionSearchQuery}
              placeholder="User's current organizations"
              onClear={() => setInstitutionSearchQuery('')}
            />
            <MainstayFlexTableHeader>
              <MainstayFlexTableHeaderCol xs={2}>
                Mongo ID
              </MainstayFlexTableHeaderCol>
              <MainstayFlexTableHeaderCol xs={2}>
                Organization display name
              </MainstayFlexTableHeaderCol>
              <MainstayFlexTableHeaderCol xs={2}>
                Organization abbreviation
              </MainstayFlexTableHeaderCol>
              <MainstayFlexTableHeaderCol xs={2}>
                Bot's name
              </MainstayFlexTableHeaderCol>
              <MainstayFlexTableHeaderCol xs={3}>
                Switch Link
              </MainstayFlexTableHeaderCol>
            </MainstayFlexTableHeader>
            {selectedUser?.institutions.map(i => {
              const institution = institutions.find(a => a.id === i)
              if (!institution) {
                return undefined
              }
              if (
                !(
                  institution.displayName +
                  institution.id +
                  institution.oliName
                )
                  .toLowerCase()
                  .includes(institutionSearchQuery.toLowerCase())
              ) {
                return undefined
              }
              const deleteDisabled =
                selectedUser.email === currentUserEmail &&
                institution.id === currentInstitution
              return (
                <MainstayFlexTableRow key={institution.id}>
                  <MainstayFlexTableCol
                    className="d-flex align-items-center"
                    xs={2}>
                    {institution.id}
                  </MainstayFlexTableCol>
                  <MainstayFlexTableCol
                    className="d-flex align-items-center"
                    xs={2}>
                    {institution.displayName}
                  </MainstayFlexTableCol>
                  <MainstayFlexTableCol
                    className="d-flex align-items-center"
                    xs={2}>
                    {institution.abbr}
                  </MainstayFlexTableCol>
                  <MainstayFlexTableCol
                    className="d-flex align-items-center"
                    xs={2}>
                    {institution.oliName}
                  </MainstayFlexTableCol>
                  <MainstayFlexTableCol
                    className="d-flex align-items-center"
                    xs={3}>
                    <Link to={`/switch/${institution.id}/`}>
                      Switch to {institution.displayName}
                    </Link>
                  </MainstayFlexTableCol>
                  <MainstayFlexTableCol
                    className="d-flex align-items-center"
                    xs={1}>
                    <ButtonIconRound
                      className="text-muted hover-text-new-ui-danger w-content"
                      disabled={deleteDisabled}
                      onClick={() =>
                        removeFromInstitution(
                          institution.id,
                          selectedUser.email
                        ).then(res => {
                          if (isRight(res)) {
                            setUsers(prev => [
                              ...prev.filter(
                                user => user.email !== res.right.email
                              ),
                              res.right,
                            ])
                            toast.info(
                              `Removed the user from ${institution.id}`
                            )
                          } else {
                            toast.error(
                              'Unable to remove user from organization'
                            )
                          }
                        })
                      }
                      icon="delete"
                      tooltipText={
                        deleteDisabled
                          ? 'Cannot remove from currently active institution'
                          : 'Remove user from organization'
                      }
                      tooltipPlacement="top"
                    />
                  </MainstayFlexTableCol>
                </MainstayFlexTableRow>
              )
            })}
          </MainstayFlexTable>
        </>
      )}
    </AuditPageContainer>
  )
}

export default AuditUserAccess

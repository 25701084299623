import { fetchContacts } from 'api'
import axios, { CancelTokenSource } from 'axios'
import 'components/AudienceDetailActivity/AudienceDetail.scss'
import {
  Chips,
  ContactsListFilterModal,
  FilterButton,
  FilterEnum,
} from 'components/FilterContactsModal/FilterContactsModal'
import { TransportIcon } from 'components/Icons/TranspotIcon/TransportIcon'
import { LabelPopover } from 'components/LabelPopover/LabelPopover'
import { SearchInput } from 'components/SearchInput/SearchInput'
import {
  IAsyncRows,
  IAsyncTableOptions,
  SortTableAsync,
  useResetPageNumberOnSearch,
} from 'components/TableAsyncSort/TableAsyncSort'
import { MainstayFlexTableCol } from 'mainstay-ui-kit/MainstayFlexCol/MainstayFlexCol'
import { MainstayFlexTableRow } from 'mainstay-ui-kit/MainstayFlexRow/MainstayFlexRow'
import React, { useReducer } from 'react'
import { useHistory, useLocation } from 'react-router'
import { useDebounce, useFeatures, useSelector } from 'util/hooks'
import {
  appendQueryFilter,
  getQueryFilters,
  removeQueryFilter,
} from 'util/queryFilters'
import { NavLink } from 'util/routing'
import { parseQueryString, queryParamToArray } from 'util/string'
import { IntlPhoneNumber } from 'components/PurchasePhoneNumbersForm/PurchasePhoneNumbersForm'
import { getInstitutionDateFormat } from 'store/triage/institution/selectors'
import strftime from 'strftime'
import {
  filterContactsReducer,
  DEFAULT_FILTERS_INITIAL_STATE,
} from 'components/FilterContactsModal/filterContactsReducer'

export const humanizedFilterLabels: { [k: string]: string } = {
  sms: 'SMS',
  twilio: 'SMS',
  web: 'Web',
  web_bot: 'Web',
  facebook: 'Facebook',
  slack: 'Slack',
  active: 'Active',
  archived: 'Archived',
  test: 'Only Test Users',
  'non-test': 'No Test Users',
}

export const getFirstQueryParam = (
  searchQuery: string | string[] | undefined
) => {
  if (Array.isArray(searchQuery)) {
    return searchQuery[0]
  }
  return searchQuery ?? ''
}

interface ISortableContactForAudienceProps {
  audienceId?: number
  cacheState?: string
  cacheRefreshComponent?: React.ReactNode
}

export const getContactsFilterDataFromParams = (locationSearch: string) => ({
  channel: queryParamToArray(parseQueryString(locationSearch)['channel']),
  filter: queryParamToArray(parseQueryString(locationSearch)['filter']),
  testUser: queryParamToArray(parseQueryString(locationSearch)['testUser']),
})
interface ISortableContactListProps {
  shouldResetFilters: boolean
  onSelectContactNavLink: string
  setTotalCount?: (_: number) => void
  setContactsLoading: ((_: boolean) => void) | undefined
}

export const SortableContactList = ({
  audienceId,
  shouldResetFilters,
  onSelectContactNavLink,
  setTotalCount,
  setContactsLoading,
  cacheState,
  cacheRefreshComponent,
}: ISortableContactListProps & ISortableContactForAudienceProps) => {
  const history = useHistory()
  const location = useLocation()
  const dateFormat = useSelector(getInstitutionDateFormat)

  const [showFiltersModal, setShowFiltersModal] = React.useState(false)
  const [searchQuery, setSearchQuery] = React.useState(
    getFirstQueryParam(getQueryFilters(window.location)['search'])
  )
  const [filterData, setFilterData] = React.useState(
    getContactsFilterDataFromParams(location.search)
  )

  const debouncedSearchQuery = useDebounce(searchQuery, 600)
  useResetPageNumberOnSearch(debouncedSearchQuery)
  const cancelTokenRef = React.useRef<CancelTokenSource | undefined>(undefined)

  React.useEffect(() => {
    setSearchQuery(
      getFirstQueryParam(getQueryFilters(window.location)['search'])
    )
  }, [])

  React.useEffect(() => {
    setFilterData(getContactsFilterDataFromParams(location.search))
  }, [location.search])

  React.useEffect(() => {
    if (debouncedSearchQuery === '') {
      history.push(removeQueryFilter(window.location, 'search'))
    } else {
      history.replace(
        appendQueryFilter(window.location, 'search', debouncedSearchQuery)
      )
    }
  }, [history, debouncedSearchQuery])

  const { hasFeature, FeaturesType } = useFeatures()

  const loadContacts = React.useCallback(
    async (
      { page = 1, sortBy, order }: IAsyncTableOptions,
      pageSize: number
    ): Promise<IAsyncRows> => {
      setContactsLoading?.(true)
      if (cancelTokenRef?.current) {
        cancelTokenRef.current.cancel()
      }
      cancelTokenRef.current = axios.CancelToken.source()
      try {
        const res = await fetchContacts(
          cancelTokenRef.current.token,
          pageSize,
          debouncedSearchQuery ?? '',
          undefined,
          filterData,
          sortBy,
          pageSize * (page - 1),
          order,
          audienceId
        )
        setContactsLoading?.(false)
        setTotalCount?.(res.data.count)
        return {
          total: res.data.count,
          rows: res.data.results.map(rowRes => {
            const {
              name,
              phone,
              email,
              id,
              created,
              contactLabels,
              transport,
              countryCallingCode,
            } = rowRes
            return [
              <NavLink
                to={`${onSelectContactNavLink}${id}${window.location.search}`}
                key={id}
                className="text-mainstay-dark-blue-80 w-100 student-row pointer">
                <MainstayFlexTableRow>
                  <MainstayFlexTableCol
                    className="text-wrap d-flex no-gutters align-items-center"
                    xs={2}>
                    <div
                      className="col-3 text-center"
                      style={{ lineHeight: '16px' }}>
                      {transport ? (
                        <TransportIcon transport={transport} isOffline />
                      ) : null}
                    </div>
                    {name.first || '-'}
                  </MainstayFlexTableCol>
                  <MainstayFlexTableCol
                    className="text-wrap align-self-center"
                    xs={2}>
                    {name.last || '-'}
                  </MainstayFlexTableCol>
                  <MainstayFlexTableCol
                    className="text-wrap align-self-center"
                    xs={2}>
                    {phone ? (
                      hasFeature(FeaturesType.INTL_TEXTING_V1) ? (
                        <IntlPhoneNumber
                          phoneNumber={{
                            phoneE164: `+${countryCallingCode}${phone}`,
                          }}
                        />
                      ) : (
                        phone
                      )
                    ) : (
                      '-'
                    )}
                  </MainstayFlexTableCol>
                  <MainstayFlexTableCol
                    className="text-wrap align-self-center"
                    xs={2}>
                    {email || '-'}
                  </MainstayFlexTableCol>
                  <MainstayFlexTableCol xs={2}>
                    {contactLabels.length ? (
                      <LabelPopover
                        labels={contactLabels.map(label => label.text)}
                        popoverId={id}
                      />
                    ) : (
                      '-'
                    )}
                  </MainstayFlexTableCol>
                  <MainstayFlexTableCol
                    className="text-wrap align-self-center"
                    xs={2}>
                    {strftime(dateFormat, new Date(created))}
                  </MainstayFlexTableCol>
                </MainstayFlexTableRow>
              </NavLink>,
            ]
          }),
        }
      } catch (axiosError) {
        if (axios.isCancel(axiosError)) {
          setContactsLoading?.(true)
          return { total: 0, rows: [], error: false }
        }
        return { total: 0, rows: [], error: true }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      debouncedSearchQuery,
      filterData,
      audienceId,
      onSelectContactNavLink,
      setContactsLoading,
      setTotalCount,
      cacheState,
    ]
  )

  const [initialFiltersState, dispatch] = useReducer(
    filterContactsReducer,
    DEFAULT_FILTERS_INITIAL_STATE
  )

  const eventLocation = 'contacts'

  return (
    <section>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex mb-2rem align-items-center">
          <SearchInput
            className="w-360"
            onChange={e => {
              setSearchQuery(e.target.value)
            }}
            value={searchQuery}
            placeholder="Name, Email, Phone, Label, or CRM ID"
            onClear={() => setSearchQuery('')}
            eventAction="change"
            eventLocation={eventLocation}
            eventObject="search contacts"
            clearEventAction="click"
            clearEventObject="clear contact search"
          />
          <FilterButton
            className="ml-1"
            eventLocation={eventLocation}
            onClick={() => setShowFiltersModal(true)}
          />
        </div>
        {audienceId && cacheRefreshComponent}
      </div>
      <ContactsListFilterModal
        initialState={initialFiltersState}
        dispatch={dispatch}
        show={showFiltersModal}
        onClose={() => setShowFiltersModal(false)}
        enabledFilters={{
          [FilterEnum.channel]: { multi: true },
          [FilterEnum.filter]: {},
          [FilterEnum.testUser]: {},
        }}
        eventLocation="contacts"
        shouldResetFilters={shouldResetFilters}
      />
      <Chips
        enabledFilters={{
          [FilterEnum.channel]: { multi: true, actionType: 'clear' },
          [FilterEnum.filter]: { actionType: 'clear' },
          [FilterEnum.testUser]: { actionType: 'clear' },
        }}
      />
      <div className="d-flex flex-column contact-table">
        <SortTableAsync
          itemsName="contacts"
          initialTableOptions={{ sortBy: 'lastName', order: 'asc' }}
          loadRows={loadContacts}
          pageSize={20}
          eventAction="click"
          eventLocation={eventLocation}
          rowEventObject="individual contact"
          pagerEventObject="of contacts"
          columns={[
            {
              title: 'First',
              xs: 2,
              alignment: 'start',
              sortByField: 'firstName',
              eventLocation,
              eventTrackerName: 'first name',
              rowPrefix: <div className="col-3" />,
            },
            {
              title: 'Last',
              xs: 2,
              alignment: 'start',
              sortByField: 'lastName',
              eventLocation,
              eventTrackerName: 'last name',
            },
            {
              title: 'Phone',
              xs: 2,
              alignment: 'start',
              sortByField: 'phone',
              eventLocation,
              eventTrackerName: 'phone',
            },
            {
              title: 'Email',
              xs: 2,
              alignment: 'start',
              sortByField: 'email',
              eventLocation,
              eventTrackerName: 'email',
            },
            {
              title: 'Labels',
              xs: 2,
              alignment: 'start',
            },
            {
              title: 'Date Added',
              xs: 2,
              alignment: 'start',
              sortByField: 'created',
              eventLocation,
              eventTrackerName: 'email',
            },
          ]}
        />
        <div />
      </div>
    </section>
  )
}

import React from 'react'
import { connect } from 'react-redux'
import { RootState as IState } from 'store/store'
import { getEnabledFeatures } from 'store/triage/profile/selectors'

export interface IFeatureProps {
  readonly name: FeaturesType
  readonly children?: () => React.ReactNode
  readonly features?: ReadonlyArray<FeaturesType>
  readonly fallback?: () => React.ReactNode
}

export function FeatureBasic({
  name,
  children,
  features,
  fallback,
}: IFeatureProps) {
  if (!hasFeature(features, name)) {
    if (fallback != null) {
      return <>{fallback()}</>
    }
    return null
  }
  if (typeof children === 'function') {
    return <>{children()}</>
  }
  return <>{children}</>
}

function mapStateToProps(state: IState): Pick<IFeatureProps, 'features'> {
  return {
    features: getEnabledFeatures(state),
  }
}

export function hasFeature(
  features: ReadonlyArray<FeaturesType> | undefined,
  feature: FeaturesType
): boolean {
  return features != null && features.includes(feature)
}

/**
 * Convenient feature gate which can be used in JSX.
 * @param name - The feature to check
 * @param children - A function which returns the JSX to render if the feature check passes
 * @param fallback - (Optional) A function which returns the JSX to render if the feature check does not pass
 */
export const Feature = connect(mapStateToProps)(FeatureBasic)

/**
 * All existing features.
 * Must be sorted.
 * Make sure to update backend with new / removed features.
 */
export enum FeaturesType {
  ACCOUNT_ACCESS_TOOL = 'users/account-access-audit',
  AUDIENCE_REFRESH_PER_CHANGES_V2 = 'audiences/smart-cache-refresh/v2',
  CONVERSATIONS_COUNT = 'conversations/count',
  COPY_SCRIPT_TO_LIBRARY = 'script-library/copy-to-lib',
  DATA_TRIGGERED_CAMPAIGNS = 'campaigns/data-triggered',
  DISABLE_INTRO_SCRIPT = 'disable-intro-script',
  ENABLE_GEOLOCATION_FIELDS = 'enable-geolocation-fields',
  ESCALATION_EMAIL_CONVERSATION_SUMMARY = 'escalations/conversation-summary',
  GENERATIVE_AI_AUTOMATIC_FUZZY_QUESTIONS = 'generative-ai/automatic-fuzzy-questions',
  GENERATIVE_AI_CONVERSATION_SUMMARY = 'generative-ai/conversation-summary',
  GENERATIVE_AI_FALLBACK_RESPONSES = 'rematchmaker-rag-responses-for-idks',
  GENERATIVE_AI_FUZZY_QUESTIONS = 'generative-ai/fuzzy-questions',
  GENERATIVE_AI_KNOWLEDGE_BASE_ANSWERS = 'generative-ai/knowledge-base-answers',
  GENERATIVE_AI_LIVE_CHAT_SUGGESTIONS = 'conversations/generative-ai-live-responses',
  GENERATIVE_AI_PARTNER_FACING_SCRAPER = 'generative-ai/partner-facing-scraper',
  HELLO_PAGES = 'settings/hello-pages',
  HIDE_IMAGES_IN_CONVERSATIONS = 'conversations/hide-images',
  IGNORE_LATE_CAMPAIGN_RESPONSES = 'campaigns/ignore-late-responses',
  INCOMMON_SSO = 'settings/incommon-sso',
  INTL_TEXTING_V1 = 'intl-texting/v1',
  KNOWLEDGE_BASE_CONTACT_FEEDBACK = 'knowledge-base/contact-feedback',
  MULTI_CHANNEL_CONTACTS = 'multi-channel-contacts',
  MULTI_CHANNEL_CONTACTS_REPORTS = 'multi-channel-contacts/reports',
  PREFERRED_NAME_FEATURE = 'contacts/preferred-name',
  PERMS_REGIONS_ENABLED = 'perms/regions_enabled',
  PUBLIC_API_INCLUDE_LAST_MODIFIED_AT = 'public-api/include-last-modified-at',
  RAGTIME = 'ragtime',
  REMATCHMAKER_EXPERIMENT = 'rematchmaker-experiment',
  REMATCHMAKER_GPT4 = 'rematchmaker-gpt4',
  REVIEWER_ROLE = 'reviewer-role',
  SCHOLARSHIP_BOT_HASHTAG_COMMAND_SHIM = 'scholarship-bot/hashtag-command-shim',
  SLACK_SETTTINGS_PAGE = 'settings/slack-page',
  WEBHOOKS_ESCALATION = 'webhooks/escalations',
}

export interface IFeature {
  readonly feature: FeaturesType
  readonly name: string
  readonly helpText: string | JSX.Element
  readonly engineeringOnly?: true
  readonly hidden?: boolean
  // features visible to partners are displayed in the Miscellaneous section
  readonly visibleToPartners?: boolean
}

/**
 * Metadata about features.
 * Must be sorted.
 */
export const AVAILABLE_FEATURES: ReadonlyArray<IFeature> = [
  {
    feature: FeaturesType.ACCOUNT_ACCESS_TOOL,
    name: 'Enable user audit tool',
    helpText: 'Allow access to interal User Access Audit Tool in Mascot.',
    hidden: true, // This feature is hidden because it's meant to be enabled/disabled per user and not per institution
  },
  {
    feature: FeaturesType.HIDE_IMAGES_IN_CONVERSATIONS,
    name: 'Conversations — Hide incoming images',
    helpText:
      'If enabled, a text placeholder [Incoming image] is displayed instead of actual images in conversations.',
    visibleToPartners: true,
  },
  {
    feature: FeaturesType.HELLO_PAGES,
    name: 'Hello Pages Configuration',
    helpText:
      'If enabled, allows users to configure Hello Pages for their institution.',
    engineeringOnly: true,
  },
  {
    feature: FeaturesType.IGNORE_LATE_CAMPAIGN_RESPONSES,
    name: 'Campaigns — Ignore late campaign responses',
    helpText:
      'If enabled, any responses that come in after a campaign has expired for a contact will not impact campaign engagement stats.',
    visibleToPartners: true,
  },
  {
    feature: FeaturesType.CONVERSATIONS_COUNT,
    name: 'Conversations — Count all contacts when loading list',
    helpText:
      'This may cause the conversations page to timeout on large institutions.',
    hidden: true,
  },
  {
    feature: FeaturesType.COPY_SCRIPT_TO_LIBRARY,
    name: 'Copy to Script Library',
    helpText:
      '**User Flag, this is not typically to be enabled at institution level** Allows certain mainstay users to copy scripts from orgs to the script library.',
    engineeringOnly: true,
    hidden: true,
  },
  {
    feature: FeaturesType.KNOWLEDGE_BASE_CONTACT_FEEDBACK,
    name: 'Webchat Feedback',
    helpText:
      'Web chat users will be prompted for feedback and results will be shown in the knowledge base section.',
    visibleToPartners: true,
  },
  {
    feature: FeaturesType.PREFERRED_NAME_FEATURE,
    name: 'Preferred Name Feature - #changename command',
    helpText:
      'Display #changename command in list on the Commands page and in Quick Actions menu on webchat. If toggled off, #changename dialog will not be triggerable from a conversation.',
    visibleToPartners: true,
  },
  {
    feature: FeaturesType.INCOMMON_SSO,
    name: 'InCommon SSO',
    helpText:
      "Allow users to log into Mainstay using InCommon authentication. Connect your account with your insitution's InCommon ID",
    hidden: true,
  },
  {
    feature: FeaturesType.DATA_TRIGGERED_CAMPAIGNS,
    name: 'Data-triggered Campaigns',
    helpText: (
      <div>
        <p className="caption text-white">
          Display new campaign creation UI that allows for creation of
          data-triggered campaigns.
        </p>
        <p className="caption text-white">
          🚨 CAUTION 🚨 Disabling this feature will mark all unprocessed
          ContactAudienceChangeEvent docs for this institution as skipped.
        </p>
      </div>
    ),
    engineeringOnly: true,
  },
  {
    feature: FeaturesType.DISABLE_INTRO_SCRIPT,
    name: 'Disable Intro Script for Unknown SMS contacts',
    helpText:
      'Allows users to disable their intro script for new SMS contacts. Useful when an org wants to use various hashtag commands to send different intro scripts to different cohorts.',
    hidden: true,
  },
  {
    feature: FeaturesType.GENERATIVE_AI_AUTOMATIC_FUZZY_QUESTIONS,
    name: 'Generate Fuzzy Questions for new Understandings',
    helpText:
      'If enabled, when you create a new understanding in the Knowledge Base or Knowledge Maintenance, this will automatically generate 10 fuzzy questions based on the summary question.',
    hidden: true,
  },
  {
    feature: FeaturesType.GENERATIVE_AI_LIVE_CHAT_SUGGESTIONS,
    name: 'AI-assisted live chat',
    helpText:
      'Dynamically generates contextually relevant message suggestions during Live Chat conversations.',
    hidden: true,
  },
  {
    feature: FeaturesType.GENERATIVE_AI_FUZZY_QUESTIONS,
    name: 'Fuzzy question generator',
    helpText:
      'Generates variations of an understanding’s summary question to improve the accuracy of knowledge matching.',
    hidden: true,
  },
  {
    feature: FeaturesType.GENERATIVE_AI_PARTNER_FACING_SCRAPER,
    name: 'Partner facing scraper',
    helpText:
      'Makes the Knowledge scraper / Knowledge sources page available to the partners.',
    hidden: true,
  },
  {
    feature: FeaturesType.GENERATIVE_AI_KNOWLEDGE_BASE_ANSWERS,
    name: 'Knowledge Base answers',
    helpText:
      'Creates an answer for an understanding’s summary question in the Knowledge Base.',
    hidden: true,
  },
  {
    feature: FeaturesType.GENERATIVE_AI_FALLBACK_RESPONSES,
    name: 'Flash Responses',
    helpText:
      'Synthesizes a response to a learner’s incoming question if there is no relevant match in the Knowledge Base.',
    hidden: true,
    engineeringOnly: true,
  },
  {
    feature: FeaturesType.GENERATIVE_AI_CONVERSATION_SUMMARY,
    name: 'Conversation Summary',
    helpText:
      'Generate a summary recent messages, displayed on the contact profile.',
    engineeringOnly: true,
    hidden: true,
  },
  {
    feature: FeaturesType.INTL_TEXTING_V1,
    name: 'Intl Texting v1',
    helpText:
      "Enables various platform support described in Balto's v1 intl texting epic",
    engineeringOnly: true,
  },
  {
    feature: FeaturesType.MULTI_CHANNEL_CONTACTS,
    name: 'Multi-Channel Contacts',
    helpText: 'Support for contacts with more than one messaging channel',
    engineeringOnly: true,
  },
  {
    feature: FeaturesType.MULTI_CHANNEL_CONTACTS_REPORTS,
    name: 'Multi-Channel Contacts - Standardized reports',
    helpText:
      'Standardizes fields in reports across the platform including the API and insights reports.',
    engineeringOnly: true,
  },
  {
    feature: FeaturesType.PUBLIC_API_INCLUDE_LAST_MODIFIED_AT,
    name:
      'Public API - Include last_modified_at field in /contacts GET endpoint responses',
    helpText:
      'If this feature is enabled, last_modified_at will be available in responses from /contacts retrieve and list endpoints. Feature-flagged due to the requirement to refresh fields on the Salesforce side to prevent API failures.',
    engineeringOnly: true,
  },
  {
    feature: FeaturesType.PERMS_REGIONS_ENABLED,
    name: 'Regions Enabled',
    helpText: 'Regions are a basic permissions system',
    hidden: true,
  },
  {
    feature: FeaturesType.RAGTIME,
    name: 'Ragtime Mode',
    helpText:
      'Allow Scripts to include an Action for entering a discussion with a generative AI bot, based on a topic-specific prompt.',
    engineeringOnly: true,
  },
  {
    feature: FeaturesType.REMATCHMAKER_EXPERIMENT,
    name: 'ReMatchMaker Experiment',
    helpText:
      'An experimental feature to use generative ai as a replacement to MatchMaker',
    engineeringOnly: true,
    hidden: true,
  },
  {
    feature: FeaturesType.REMATCHMAKER_GPT4,
    name: 'ReMatchMaker GPT-4',
    helpText:
      'When enabled, the GPT-4 engine will be used. When disabled, GPT-3.5turbo will be used.',
    engineeringOnly: true,
    hidden: true,
  },
  {
    feature: FeaturesType.REVIEWER_ROLE,
    name: 'Reviewer role',
    helpText: 'Enables the Reviewer role',
    engineeringOnly: true,
  },
  {
    feature: FeaturesType.SCHOLARSHIP_BOT_HASHTAG_COMMAND_SHIM,
    name: 'Scholarship Bot - Hashtag Command Shim',
    helpText:
      'Enables the #scholarshipbot hashtag command, which when used will create a new scholarship inquiry with the Scholarship Bot service.',
    engineeringOnly: true,
  },
  {
    feature: FeaturesType.SLACK_SETTTINGS_PAGE,
    name: 'Slack Settings Page',
    helpText: 'Display the Slack settings page in the settings page sidebar.',
    engineeringOnly: true,
  },
  {
    feature: FeaturesType.WEBHOOKS_ESCALATION,
    name: 'Webhooks - Escalations',
    helpText:
      'Allows partners to add webhooks and forward automatic/manual escalations to those webhooks',
    visibleToPartners: true,
  },
  {
    feature: FeaturesType.ENABLE_GEOLOCATION_FIELDS,
    name: 'Geolocation Fields in Importer and Escalation Rules',
    engineeringOnly: true,
    helpText:
      'Allows partners to map to the `location` fields (address1, city, state, zip, country) via the Importer UI and use geo-matching conditions in Escalations/Email Forwarding.',
  },
  {
    feature: FeaturesType.AUDIENCE_REFRESH_PER_CHANGES_V2,
    name: 'Use v2 of refresh_audiences_for_institution_based_on_changes code',
    engineeringOnly: true,
    helpText:
      'This code was duplicated and refactored around 2024-12-16. This FF forces institutions to use the v2 version instead of the v1 version.',
  },
] as const

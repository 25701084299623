import React from 'react'
import { AHIcon } from 'components/Icons/AHIcon/AHIcon'
import { Input } from 'components/Input/Input'
import { Button } from 'components/Button/Button'
import cls from 'classnames'
import { UnderstandingQuestionSummaryIcon } from 'components/Icons/UnderstandingQuestionSummaryIcon/UnderstandingQuestionSummaryIcon'
import { Select } from 'components/Select/Select'
import { LabelIcon } from 'components/Icons/LabelIcon/LabelIcon'
import Tooltip from 'components/Tooltip/Tooltip'
import sortBy from 'lodash/sortBy'
import { useSelector, useDispatch, useDebounce } from 'util/hooks'
import { Link } from 'util/routing'
import { removeQueryFilter } from 'util/queryFilters'
import * as api from 'api'
import { DropdownIndicator } from 'components/SettingsEscalation/reactSelectUtils'
import { addKnowledgeSeed } from 'store/knowledgeSeeder/actions'
import { isLeft } from 'fp-ts/lib/Either'
import {
  WebData,
  isSuccessOrRefetching,
  isFailure,
  isLoading,
  isRefetching,
  Success,
  Failure,
  Loading,
  isInitial,
  isSuccess,
} from 'store/webdata'
import queryString from 'query-string'
import { push } from 'connected-react-router'
import { PanelDrawer } from 'components/Panel/Panel'
import { Spinner } from 'components/Spinner/Spinner'
import { ExpandButton } from 'components/ExpandButton/ExpandButton'
import { Creatable, IconlessControl } from 'components/AutoSuggest/AutoSuggest'
import { ActionMeta, ValueType } from 'react-select/lib/types'
import { detailOrDefault } from 'api/http'
import { AxiosError } from 'typings/axios'
import { InfoAlert } from 'components/Alert/Alert'
import DraftEditor from 'components/DraftEditor/DraftEditor'
import { UnderstandingApprovalMenuV2 } from 'components/UnderstandingApprovalMenu/UnderstandingApprovalMenu'
import { WireframeMessage } from 'components/WireframeMessage/WireframeMessage'
import { getKnowledgeBaseReviewItems } from 'page/knowledge-base/KnowledgeReview/selectors'
import { KnowledgeBaseReviewItemsStatus } from 'page/knowledge-base/KnowledgeReview/reducer'
import 'components/KnowledgeSeedEditPanel/KnowledgeSeedEditPanel.scss'
import {
  knowledgeReviewReasonToEnum,
  MARK_TITLES,
} from 'page/conversations-v2/ConversationsDetail/'
import { ConversationMessageLink } from 'page/knowledge-base/KnowledgeReview/KnowledgeReviewTable/KnowledgeReviewTableRow/KnowledgeReviewTableRow'
import { fetchKnowledgeBaseTopics } from 'page/knowledge-base/actions'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { getKnowledgeBase } from 'page/knowledge-base/selectors'
import { KnowledgeBaseStatus } from 'page/knowledge-base/reducer'
import MarkForKnowledgeReviewNotesModal from 'page/conversations-v2/ConversationsDetail/MessageRow/MarkForKnowledgeReviewNotesModal/MarkForKnowledgeReviewNotesModal'
import { PERMISSIONS } from 'util/permissions/permissions'
import PermissionsGuard from 'util/permissions/PermissionsGuard'
import { KnowledgeDrawerHeader } from 'components/KnowledgeSeedEditPanel/KnowledgeSeedEditPanelClone'
import { isKeyOfObject } from 'util/typeguards'
import { MainstayDrawerFooter } from 'components/MainstayDrawerFooter/MainstayDrawerFooter'
import { getAttributeByIdMapping } from 'store/personalization/selectors'
import { CenteredLoader } from 'components/Loader/Loader'
import { useHistory } from 'react-router-dom'

const MISCELLANEOUS = 'miscellaneous'
function NoAnswer() {
  return <div className="mainstay-body-paragraph">None</div>
}

export function SearchResult({
  onSelectAnswer,
  reviewItem,
  category,
  subCategory,
  sampleQuestion,
  answer,
  id,
  firstInList,
  className,
  onEdit,
}: {
  readonly onSelectAnswer?: () => void
  readonly reviewItem?: api.KnowledgeBaseReviewItemShapeType
  readonly category: string
  readonly subCategory: string
  readonly sampleQuestion: string
  readonly answer: string
  readonly id: string
  readonly firstInList?: boolean
  readonly onEdit?: (understandingId: string) => void
  readonly className?: string
}) {
  const { addQuestionStatus } = useSelector(getKnowledgeBaseReviewItems)
  const [expanded, setExpanded] = React.useState(true)

  const toggleExpand = () => setExpanded(s => !s)

  return (
    <div className="d-flex flex-column w-100">
      <div
        className={cls(
          'd-flex flex-column py-2',
          { 'border-top': !firstInList },
          className
        )}>
        <div className="d-flex align-items-start">
          <ExpandButton
            className="ml-2 move-chevron-up"
            expanded={expanded}
            onClick={toggleExpand}
            direction="horizontal"
          />
          <div className={cls('fw-600 ml-3')}>{sampleQuestion}</div>
        </div>
        <div className="ml-3 move-search-result">
          {reviewItem && (
            <Button
              className="align-self-end"
              height="small"
              color="link"
              disabled={
                addQuestionStatus === KnowledgeBaseReviewItemsStatus.loading
              }
              onClick={onSelectAnswer}>
              Select Answer
            </Button>
          )}
          {expanded ? (
            <div className="mr-4">
              <div className="mb-0 text-capitalize">
                {category + ' / ' + subCategory}
              </div>
              <div className="mt-2">
                <small>Answer</small>
                <div className="mainstay-body-paragraph mb-2">
                  {answer ? (
                    <WireframeMessage message={answer} />
                  ) : (
                    <NoAnswer />
                  )}
                </div>
                {onEdit ? (
                  <div
                    onClick={() => onEdit(id)}
                    className="text-link-primary hover-text-link-primary-hover pointer mb-1">
                    Edit this Understanding
                  </div>
                ) : !reviewItem ? (
                  <Link
                    to={{
                      pathname: '/knowledge',
                      search: queryString.stringify({
                        understanding_id: id,
                      }),
                    }}
                    className="text-link-primary hover-text-link-primary-hover">
                    Edit this Understanding
                  </Link>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export interface ISearchResult {
  readonly id: string
  readonly category: string
  readonly subCategory: string
  readonly sampleQuestion: string
  readonly matchingQuestion: string
  readonly answer: string
}

function useSearchResults() {
  const [state, setState] = React.useState<
    WebData<ReadonlyArray<ISearchResult>>
  >(undefined)
  const handleSearch = React.useCallback((query: string) => {
    if (!query.trim()) {
      setState(Success([]))
      return Success([])
    }
    setState(Loading())
    api
      .searchQuestionsV2({ question: query })
      .then(res => {
        setState(Success(res.data.results))
      })
      .catch(() => {
        setState(Failure(undefined))
      })
  }, [])

  return [state, handleSearch] as const
}

function Search({
  onCreate,
  results,
  onSearch,
  defaultSearch,
  reviewItem,
  onSelectAnswer,
}: {
  readonly onCreate?: (params: { summaryQuestion: string }) => void
  readonly results: WebData<ReadonlyArray<ISearchResult>>
  readonly onSearch: (query: string) => void
  readonly defaultSearch?: string
  readonly reviewItem?: api.KnowledgeBaseReviewItemShapeType
  readonly onSelectAnswer?: (result: ISearchResult) => void
}) {
  const [query, setQuery] = React.useState(defaultSearch || '')

  React.useEffect(() => {
    if (defaultSearch) {
      onSearch(query)
    }
  }, [defaultSearch, onSearch, query])

  function handleQueryChange(e: React.ChangeEvent<HTMLInputElement>) {
    const query = e.target.value
    setQuery(query)
  }

  function handleFormSubmit(e: React.FormEvent) {
    e.preventDefault()
    onSearch(query)
  }

  function handleCreateNew() {
    if (onCreate) {
      onCreate({ summaryQuestion: query })
    }
  }

  return (
    <>
      <div className="px-4">
        {reviewItem && <h3>Find an existing answer</h3>}
        {!reviewItem && (
          <div className="d-flex flex-column mt-2">
            <span className="mainstay-body-paragraph text-mainstay-dark-blue-80 d-inline">
              <span>
                Before creating a new understanding, submit a summary question
                to check for existing matches in the Knowledge Base.
              </span>
              <span>
                <Tooltip content="Why? If Understandings are too similar the bot will have a hard time deciding which to match to, which can result in a lower success rate.">
                  <AHIcon
                    name="help"
                    className="text-mainstay-dark-blue-65 ml-1 fs-16px"
                  />
                </Tooltip>
              </span>
            </span>

            <div className="d-flex align-items-start mt-3">
              <UnderstandingQuestionSummaryIcon className="move-understanding-summary-icon" />
              <div className="w-100 d-flex flex-column ml-2">
                <div className="mainstay-header-h4 text-mainstay-dark-blue-80">
                  Summary Question
                </div>
                <div className="mainstay-body-paragraph text-mainstay-dark-blue-80 my-2">
                  This is the “main” question to be answered.
                </div>
                <form onSubmit={handleFormSubmit} className="mb-3">
                  <Input
                    type="text"
                    className="text-mainstay-dark-blue"
                    placeholder="Your question here..."
                    onChange={handleQueryChange}
                    value={query}
                  />
                  <Button
                    color="primary"
                    disabled={!query.trim()}
                    className="mt-3"
                    type="submit">
                    Search
                  </Button>
                </form>
              </div>
            </div>
          </div>
        )}

        <div className="pl-4 ml-2 pr-2 test-mainstay-dark-blue-80">
          {isLoading(results) || isRefetching(results) ? (
            <div>Searching...</div>
          ) : null}
          {isFailure(results) ? (
            <div>Search failed. Please try again.</div>
          ) : null}
          {isSuccessOrRefetching(results) ? (
            <>
              {results.data.length > 0 ? (
                <div className="text-mainstay-dark-blue-80">
                  <div className="mainstay-header-h5">
                    There are {results.data.length} Understandings look similar
                    to your question
                  </div>
                  <div className="mainstay-body-paragraph">
                    If there's an Understanding with the same intent as your
                    question, click to modify that instead of creating a new
                    one.
                  </div>
                  <div>
                    <div className="mb-4">
                      {results.data.map((r, ix) => (
                        <SearchResult
                          firstInList={ix === 0}
                          reviewItem={reviewItem}
                          onSelectAnswer={() =>
                            onSelectAnswer && onSelectAnswer(r)
                          }
                          key={r.id}
                          {...r}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <p>No Understandings found.</p>
              )}
            </>
          ) : null}
        </div>
      </div>
      <PermissionsGuard permission={PERMISSIONS.UNDERSTANDING.CREATE}>
        <div className="position-fixed bottom-0 pb-3 w-100 background-white add-new-understanding-footer">
          <hr className="mt-0" />
          <div className="mainstay-header-h5 mb-2 px-4">No good matches?</div>
          <Button color="primary" className="mx-4" onClick={handleCreateNew}>
            Add New Understanding
          </Button>
        </div>
      </PermissionsGuard>
    </>
  )
}

function AnswerKind({
  name,
  marginBot = true,
}: {
  readonly name: string
  readonly marginBot?: boolean
}) {
  return <span className={cls('caption', { 'mb-2': marginBot })}>{name}</span>
}

interface IAnswerEditorProps {
  readonly className?: string
  readonly answer: string
  readonly approved: boolean
  readonly onChangeAnswer: (answer: string) => void
  readonly onChangeApproval: (approved: boolean) => void
  readonly onSave?: () => void
  readonly onCancel?: () => void
  readonly name: string
  readonly isVariant: boolean
  readonly disableAttributes?: boolean
  readonly disableApprovalMenu?: boolean
}
export function AnswerEditor({
  className,
  answer,
  approved,
  onSave,
  onCancel,
  onChangeAnswer,
  onChangeApproval,
  name,
  isVariant,
  disableAttributes,
  disableApprovalMenu,
}: IAnswerEditorProps) {
  // hide the save and cancel buttons if the callbacks aren't provided.
  const showSaveCancel = Boolean(onSave && onCancel)
  return (
    <>
      <div
        className={cls(
          'd-flex flex-column rounded border px-3 py-2 mt-3',
          {
            // we modify the border when the save and cancel buttons are showing.
            ' border-bottom-0 border-bottom-left-radius-0 border-bottom-right-radius-0': showSaveCancel,
          },
          className
        )}>
        {isVariant ? (
          <InfoAlert>
            <strong>Note:</strong> this answer will be a{' '}
            <strong className="font-italic">variant</strong>, meaning the bot
            will cycle through answers to reply with.
          </InfoAlert>
        ) : null}
        <AnswerKind name={name} marginBot={false} />
        <DraftEditor
          className="my-2"
          initialAnswer={answer}
          onChange={onChangeAnswer}
          textBoxLabel=""
          toggleResetDraftEditor={false}
          disableInstitutionAttributes={disableAttributes}
          disableContactAttributes={true}
          includeSensitiveContactAttributes={true}
          autoFocus={false}
        />
        <div className="d-flex justify-content-between my-2">
          {!disableApprovalMenu && (
            <UnderstandingApprovalMenuV2
              approved={approved}
              onApprove={() => onChangeApproval(true)}
              onUnapprove={() => onChangeApproval(false)}
              loading={false}
            />
          )}
          <div />
        </div>
      </div>
      {showSaveCancel && (
        <div className="rounded border border-top-left-radius-0 border-top-right-radius-0 p-2">
          <Button color="primary" className="mr-2" onClick={onSave}>
            Save
          </Button>
          <Button color="link" onClick={onCancel}>
            Cancel
          </Button>
        </div>
      )}
    </>
  )
}

function LoadingInlineSearchResults() {
  return (
    <div className="d-flex px-3 py-3">
      <Spinner className="stroke-mainstay-dark-blue mr-2" size="sm" />
      <div>Looking for similar understandings…</div>
    </div>
  )
}

function InlineSearchResults({
  results,
  expanded,
  toggleExpand,
  inputValue,
  onSelectSimilarUnderstanding,
}: {
  readonly results: WebData<ReadonlyArray<ISearchResult>>
  readonly expanded: boolean
  readonly toggleExpand: () => void
  readonly inputValue?: string
  readonly onSelectSimilarUnderstanding?: (understandingId: string) => void
}) {
  const history = useHistory()

  if (!inputValue) {
    return null
  }

  return (
    <div className="my-3 similar-understandings-border-outer">
      {isLoading(results) || isInitial(results) ? (
        <LoadingInlineSearchResults />
      ) : isFailure(results) ? (
        <div>failure fetching search results</div>
      ) : isSuccess(results) && results.data.length ? (
        <div className="d-flex align-items-start py-3">
          <ExpandButton
            onClick={toggleExpand}
            expanded={expanded}
            className="pl-3 pr-3"
            direction="horizontal"
          />
          <div className="d-flex flex-column">
            <div className="mainstay-header-h5">
              These Understandings look similar to your question.
            </div>
            <div className="text-mainstay-body-paragraph">
              If any have the same intent, you should edit them instead.
            </div>
          </div>
        </div>
      ) : null}
      {expanded && isSuccessOrRefetching(results) ? (
        <div
          style={{ maxHeight: 350 }}
          className="overflow-y-auto overflow-x-hidden border-top">
          {results.data.map((r, ix) => (
            <SearchResult
              key={r.id}
              {...r}
              firstInList={ix === 0}
              className="px-4"
              onEdit={() => {
                if (onSelectSimilarUnderstanding) {
                  onSelectSimilarUnderstanding(r.id)
                } else {
                  history.push(`/knowledge?understanding_id=${r.id}`)
                }
              }}
            />
          ))}
        </div>
      ) : null}
    </div>
  )
}

const MAX_QUESTION_LENGTH = 255
function SummaryQuestionInput({
  value,
  onChange,
  error,
  results,
  onSelectSimilarUnderstanding,
}: {
  readonly value: string
  readonly onChange: (_: React.ChangeEvent<HTMLInputElement>) => void
  readonly error: boolean
  readonly results: WebData<ReadonlyArray<ISearchResult>>
  readonly onSelectSimilarUnderstanding?: (understandingId: string) => void
}) {
  const [expanded, setExpanded] = React.useState(false)
  const toggleExpand = () => setExpanded(p => !p)

  const fieldTooLong = value.length > MAX_QUESTION_LENGTH
  return (
    <div className="d-flex mb-4">
      <div className="d-flex align-items-start mt-3 w-100">
        <UnderstandingQuestionSummaryIcon className="move-understanding-summary-icon" />
        <div className="w-100 d-flex flex-column ml-2">
          <div className="mainstay-header-h4 text-mainstay-dark-blue-80">
            Summary Question
          </div>
          <div className="caption">
            This is the “main” question you want to be answered.
          </div>
          <Input
            type="text"
            className="mt-3"
            required
            placeholder="Your question here..."
            defaultValue={value}
            error={error || fieldTooLong}
            onChange={onChange}
          />
          {error ? (
            <div className="invalid-feedback">summary question required</div>
          ) : null}
          {fieldTooLong ? (
            <div className="invalid-feedback">
              field exceeds {MAX_QUESTION_LENGTH} character limit
            </div>
          ) : null}

          <InlineSearchResults
            results={results}
            expanded={expanded}
            toggleExpand={toggleExpand}
            inputValue={value}
            onSelectSimilarUnderstanding={onSelectSimilarUnderstanding}
          />
        </div>
      </div>
    </div>
  )
}

type OptionType = {
  value: string
  label: string
} | null

function TopicInput({
  subCategories,
  categories,
  category,
  subCategory,
  onCategoryChange,
  onSubCategoryChange,
  errorCat,
  errorSubCat,
}: {
  readonly onCategoryChange: (_: React.ChangeEvent<HTMLSelectElement>) => void
  readonly onSubCategoryChange: (subCategory: string) => void
  readonly category: string | undefined
  readonly subCategory: string | undefined
  readonly categories: ReadonlyArray<string>
  readonly subCategories: ReadonlyArray<string>
  readonly errorSubCat: boolean
  readonly errorCat: boolean
}) {
  const {
    topics: { status },
  } = useSelector(getKnowledgeBase)
  const handleCategoryChange = (
    value: ValueType<OptionType>,
    _actionMeta: ActionMeta
  ) => {
    if (Array.isArray(value)) {
      return
    }

    // handle clearing the select via the x button
    if (!value) {
      onSubCategoryChange('')
      return
    }
    onSubCategoryChange(value.value)
  }
  return (
    <div className="d-flex mb-4">
      <div className="pr-2">
        <LabelIcon />
      </div>
      <div className="w-100">
        <div className="mainstay-header-h4">Add Topics</div>
        <div className="caption">
          How you organize your Understandings is important, as is not having
          duplicate Understandings, or Understandings that are too similar.
          Choose the topics that best represent the intent of the Understanding.
        </div>
        <div className="row mt-4 dont-hide-behind-footer">
          <div className="col-4">
            <Select
              className="focus-mainstay-dark-blue"
              onChange={onCategoryChange}
              disabled={status === KnowledgeBaseStatus.loading}
              required
              aria-label="Topic"
              value={category}
              error={errorCat}
              errorText="topic required">
              <option value="" disabled selected>
                Select
              </option>
              {sortBy(categories).map(x => (
                <option key={x} value={x}>
                  {x}
                </option>
              ))}
            </Select>
          </div>
          <span className="d-flex mt-2">/</span>
          <div className="col-5">
            <Tooltip
              content={
                subCategories.length === 0
                  ? 'Select a top level category first.'
                  : ''
              }>
              <div>
                <Creatable
                  menuPortalTarget={document.body}
                  options={sortBy(subCategories).map(e => ({
                    label: e,
                    value: e,
                  }))}
                  value={{ label: subCategory ?? '', value: subCategory ?? '' }}
                  isClearable={!!subCategory}
                  isDisabled={subCategories.length === 0}
                  onChange={handleCategoryChange}
                  styles={{
                    menuPortal: base => ({ ...base, zIndex: 9999 }),
                  }}
                  components={{
                    DropdownIndicator,
                    IndicatorSeparator: null,
                    Control: IconlessControl,
                  }}
                />
                {errorSubCat && (
                  <p className="invalid-feedback d-block">subtopic required</p>
                )}
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  )
}

function isNullOrEmpty(s: string | undefined): boolean {
  return s == null || s === ''
}

/**
 * Instantiates the UnderstandingCreator component with all of the properties it requires. Useful if you want to show the understanding creation UI without using the UnderstandingCreatePanel (because maybe you don't want a panel).
 */
export function UnderstandingCreateContent({
  defaultSummaryQuestion,
  onCreate,
  onSelectSimilarUnderstanding,
}: {
  readonly defaultSummaryQuestion: string
  readonly onCreate: (params: { readonly id: string }) => void
  readonly onSelectSimilarUnderstanding?: (id: string) => void
}) {
  const dispatch = useDispatch()

  const [searchResults, handleSearch] = useSearchResults()

  const getTopics = React.useCallback(async () => {
    dispatch(fetchKnowledgeBaseTopics.request())
    const res = await api.getExistingKBTopics()
    if (isLeft(res)) {
      toast.error('Unable to fetch topics. Please try again later.')
      dispatch(fetchKnowledgeBaseTopics.failure())
      return
    }
    dispatch(
      fetchKnowledgeBaseTopics.success({
        existingTopics: res.right,
        standardTopics: [],
      })
    )
  }, [dispatch])

  React.useEffect(() => {
    getTopics()
  }, [getTopics])

  return (
    <UnderstandingCreator
      defaultSummaryQuestion={defaultSummaryQuestion}
      onCreate={onCreate}
      results={searchResults}
      onSearch={handleSearch}
      onSelectSimilarUnderstanding={onSelectSimilarUnderstanding}
    />
  )
}

type DuplicateQuestionsResponse = {
  duplicateQuestions: {
    payload: string[]
    visible_understandings: string[]
  }
}

function UnderstandingCreator({
  defaultSummaryQuestion,
  onCreate,
  results,
  onSearch,
  onSelectSimilarUnderstanding,
}: {
  readonly defaultSummaryQuestion: string
  readonly onCreate?: (params: {
    readonly id: string
    readonly duplicateQuestions?: {
      payload: string[]
      visibleUnderstandings: string[]
    }
  }) => void
  readonly results: WebData<ReadonlyArray<ISearchResult>>
  readonly onSearch: (query: string) => void
  readonly onSelectSimilarUnderstanding?: (understandingId: string) => void
}) {
  const dispatch = useDispatch()
  const mappingResults = useSelector(getAttributeByIdMapping)
  const [summaryQuestion, setSummaryQuestion] = React.useState(
    defaultSummaryQuestion
  )
  const [selectedCategory, setSelectedCategory] = React.useState<string>()
  const [selectedSubCategory, setSelectedSubCategory] = React.useState<string>()
  const [fuzzyQuestions, setFuzzyQuestions] = React.useState<
    ReadonlyArray<{
      readonly id: string
      readonly question: string
      error?: 'dupeInPayload' | 'dupeBtwnVisibleUnderstandings'
    }>
  >([])

  const { topics } = useSelector(getKnowledgeBase)
  const [createState, setCreateState] = React.useState<
    | { type: 'initial' }
    | { type: 'loading' }
    | { type: 'success' }
    | { type: 'failure'; message: string }
  >({ type: 'initial' })
  const [errors, setErrors] = React.useState<{
    topic: boolean
    subtopic: boolean
    summaryQuestion: boolean
    duplicateQuestions?: {
      payload: string[]
      visibleUnderstandings: string[]
    }
  }>({
    topic: false,
    subtopic: false,
    summaryQuestion: false,
    duplicateQuestions: {
      payload: [],
      visibleUnderstandings: [],
    },
  })

  function findSubCategories(category: string) {
    return topics.data.existingTopics[category] || []
  }
  function handleCategoryChange(e: React.ChangeEvent<HTMLSelectElement>) {
    setSelectedCategory(e.target.value)
    setSelectedSubCategory(undefined)

    // clear relevant errors on change
    setErrors(prev => ({ ...prev, topic: false, subtopic: false }))
  }
  function handleSubCategoryChange(subCategory: string) {
    /* Miscellaneous is a special topic, in that it is omitted in the topic string.          *
     * In the event that we select miscellaneous with a standard top leve topic as subtopic, *
     * we direct the user to make that subtopic the topic instead.                           */
    if (
      selectedCategory === MISCELLANEOUS &&
      topics.data.standardTopics.includes(subCategory.toLowerCase())
    ) {
      setSelectedCategory(subCategory.toLowerCase())
      setSelectedSubCategory(undefined)
      return
    }
    setSelectedSubCategory(subCategory)

    // clear relevant errors on change
    setErrors(prev => ({ ...prev, subtopic: false }))
  }
  function handleSummaryQuestionChange(e: React.ChangeEvent<HTMLInputElement>) {
    setSummaryQuestion(e.target.value)

    // clear relevant errors on change
    setErrors(prev => ({ ...prev, summaryQuestion: false }))
  }
  const debouncedSummaryQuestion = useDebounce(summaryQuestion, 200)
  React.useEffect(() => {
    onSearch(debouncedSummaryQuestion)
  }, [debouncedSummaryQuestion, onSearch])

  const topScrollTarget = React.useRef<HTMLDivElement | null>(null)

  const handleScrollToTop = React.useCallback(() => {
    topScrollTarget.current?.scrollIntoView({ behavior: 'smooth' })
  }, [])

  function handleCreateUnderstanding() {
    const errors = {
      topic: isNullOrEmpty(selectedCategory),
      subtopic: isNullOrEmpty(selectedSubCategory),
      summaryQuestion: isNullOrEmpty(summaryQuestion),
    }
    if (
      // checking for null to make typescript happy eventhough the Object.values
      // would prevent nulls
      selectedCategory == null ||
      selectedSubCategory == null ||
      Object.values(errors).some(Boolean)
    ) {
      setErrors(errors)
      handleScrollToTop()
      return
    }
    setCreateState({ type: 'loading' })
    api
      .createUnderstandingKnowledgeEditor({
        summaryQuestion,
        answers: [],
        questions: fuzzyQuestions.map(x => ({
          question: x.question,
        })),
        topic:
          // Miscellaneous topics should be omitted, as it is frontend construct.
          // "miscellaneous" is added as an invisible subtopic to maintain topic shape
          selectedCategory === MISCELLANEOUS
            ? { first: selectedSubCategory, second: MISCELLANEOUS }
            : { first: selectedCategory, second: selectedSubCategory },
      })
      .then(res => {
        const {
          id,
          topic,
          category,
          subCategory,
          hasApprovedAnswer,
          sampleAnswer,
          sampleQuestion,
          fuzzyQuestionCount,
        } = res.data
        dispatch(
          addKnowledgeSeed({
            id,
            topic,
            category,
            subCategory,
            hasApprovedAnswer,
            sampleAnswer,
            sampleQuestion,
            fuzzyQuestionCount,
          })
        )
        setCreateState({ type: 'success' })

        if (onCreate) {
          onCreate({ id })
        }
      })
      .catch((err: AxiosError<DuplicateQuestionsResponse>) => {
        setCreateState({
          type: 'failure',
          message: detailOrDefault(err, 'Failed to create understanding.'),
        })
        if (!err.response) {
          return null
        }
        if (!isKeyOfObject('data', err.response)) {
          return null
        }
        const { duplicateQuestions } = err.response?.data
        const {
          payload,
          visible_understandings: visibleUnderstandings,
        } = duplicateQuestions
        if (payload.length || visibleUnderstandings.length) {
          setErrors({
            ...errors,
            duplicateQuestions: {
              payload,
              visibleUnderstandings,
            },
          })
          setFuzzyQuestions(
            fuzzyQuestions.map(fuzzy => {
              fuzzy.error = payload.includes(fuzzy.question)
                ? 'dupeInPayload'
                : visibleUnderstandings.includes(fuzzy.question)
                ? 'dupeBtwnVisibleUnderstandings'
                : undefined
              return fuzzy
            })
          )
        }
      })
  }

  const subCategories = findSubCategories(selectedCategory || '').map(x =>
    x.replace(selectedCategory || '', '')
  )

  if (isInitial(mappingResults) || isLoading(mappingResults)) {
    return <CenteredLoader />
  }

  return (
    <>
      <div className="px-4" ref={topScrollTarget}>
        <form>
          <SummaryQuestionInput
            results={results}
            value={summaryQuestion}
            onChange={handleSummaryQuestionChange}
            error={errors.summaryQuestion}
            onSelectSimilarUnderstanding={onSelectSimilarUnderstanding}
          />

          <TopicInput
            onCategoryChange={handleCategoryChange}
            onSubCategoryChange={handleSubCategoryChange}
            categories={Object.keys(topics.data.existingTopics)}
            subCategories={subCategories}
            category={selectedCategory}
            subCategory={selectedSubCategory}
            errorSubCat={errors.subtopic}
            errorCat={errors.topic}
          />
          {createState.type === 'failure' && (
            <p className="text-danger">{createState.message}</p>
          )}
        </form>
      </div>
      <MainstayDrawerFooter>
        <Button
          className="my-3"
          color="primary"
          loading={createState.type === 'loading'}
          onClick={handleCreateUnderstanding}>
          Next
        </Button>
      </MainstayDrawerFooter>
    </>
  )
}
// Scroll to top of creation panel when we render.
// Users scroll to the bottom of the search panel so we need to manually scroll
// them up to the top for the next panel.
function ScrollToMe({ state }: { readonly state: 'search' | 'create' | null }) {
  const ref = React.useRef<HTMLDivElement>(null)
  React.useEffect(() => {
    if (state === 'create') {
      setTimeout(() => ref.current?.scrollIntoView(), 100)
    }
  }, [state])
  return <div ref={ref} />
}

export function UnderstandingCreatePanel({
  state,
  summaryQuestion,
  question,
  onCreateStart,
  onCreateFinished,
  onClose,
  reviewItem,
  onSelectAnswer,
}: {
  readonly state: 'search' | 'create' | null
  readonly summaryQuestion: string
  readonly onCreateStart?: (params: {
    readonly summaryQuestion: string
  }) => void
  readonly onCreateFinished?: (params: {
    readonly id: string
    readonly duplicateQuestions?: {
      payload: string[]
      visibleUnderstandings: string[]
    }
  }) => void
  readonly onClose?: () => void
  readonly question?: string
  readonly reviewItem?: api.KnowledgeBaseReviewItemShapeType
  readonly onSelectAnswer?: (result: ISearchResult) => void
}) {
  const dispatch = useDispatch()

  const [searchResults, handleSearch] = useSearchResults()
  const [savingNote, setSavingNote] = React.useState<boolean>(false)
  const [showKBReviewModal, setKBReviewModal] = React.useState(false)
  const [reviewItemNote, setReviewItemNote] = React.useState<string | null>(
    reviewItem?.note ?? null
  )

  const closeUrl = {
    search: removeQueryFilter(window.location, 'create_understanding'),
  }

  const handleClose = () => {
    dispatch(push(closeUrl))
  }

  const onApplyKnowledgeReviewNote = async (note: string | null) => {
    if (!reviewItem) {
      return
    }
    setSavingNote(true)
    const res = await api.conversationsV2MarkForKnowledgeReview({
      messageId: reviewItem.message.id,
      mark: {
        reason: knowledgeReviewReasonToEnum(reviewItem.reason),
        note: note || null,
      },
    })
    setSavingNote(false)

    if (isLeft(res)) {
      toast.error('Failed to set note.')
      return
    }

    toast.success('Note successfully saved.')

    setReviewItemNote(note)
    setKBReviewModal(prev => !prev)
  }

  const getTopics = React.useCallback(async () => {
    dispatch(fetchKnowledgeBaseTopics.request())
    const [existingTopicsRes, standardTopicsRes] = await Promise.all([
      api.getExistingKBTopics(),
      api.getStandardKBTopics(),
    ])
    if (isLeft(existingTopicsRes) || isLeft(standardTopicsRes)) {
      toast.error('Unable to fetch topics. Please try again later.')
      dispatch(fetchKnowledgeBaseTopics.failure())
      return
    }
    dispatch(
      fetchKnowledgeBaseTopics.success({
        existingTopics: existingTopicsRes.right,
        standardTopics: standardTopicsRes.right,
      })
    )
  }, [dispatch])

  React.useEffect(() => {
    getTopics()
  }, [getTopics])

  return (
    <>
      <PanelDrawer
        panelContainerClassName={cls({
          'dont-hide-behind-footer': state === 'search',
          'h-100': state === 'create',
        })}
        onClose={onClose || handleClose}
        header={
          <div className="d-flex justify-content-between flex-shrink-0">
            {!reviewItem ? (
              <KnowledgeDrawerHeader
                title="Create New Understanding"
                onClose={onClose || handleClose}
              />
            ) : (
              <div className="d-flex flex-column px-4">
                <h3>"{reviewItem.message.body}"</h3>
                <ConversationMessageLink reviewItem={reviewItem} />
                <span className="pt-2">
                  {reviewItem.reason ? MARK_TITLES[reviewItem.reason] : '-'}
                </span>
                <p className="pt-1 mb-0">{reviewItemNote}</p>
                <Button
                  onClick={() => setKBReviewModal(true)}
                  color="link"
                  className="pt-1 width-0 pl-0 ml-0">
                  {`${reviewItemNote ? 'Edit' : 'Add'} Notes`}
                </Button>
                <hr />
              </div>
            )}
          </div>
        }>
        <ScrollToMe state={state} />
        <div>
          {state === 'search' ? (
            <Search
              onCreate={onCreateStart}
              results={searchResults}
              onSearch={handleSearch}
              defaultSearch={question}
              reviewItem={reviewItem}
              onSelectAnswer={onSelectAnswer}
            />
          ) : state === 'create' ? (
            <UnderstandingCreator
              onCreate={onCreateFinished}
              defaultSummaryQuestion={summaryQuestion}
              results={searchResults}
              onSearch={handleSearch}
            />
          ) : null}
        </div>
      </PanelDrawer>
      {showKBReviewModal && reviewItem && (
        <MarkForKnowledgeReviewNotesModal
          isLoading={savingNote}
          onSubmit={onApplyKnowledgeReviewNote}
          isOpen={showKBReviewModal}
          toggle={() => setKBReviewModal(prev => !prev)}
          note={reviewItem.note || ''}
        />
      )}
    </>
  )
}

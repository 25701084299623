import * as React from 'react'
import { connect } from 'react-redux'
import { RootState as IState, Dispatch } from 'store/store'
import {
  getAggregateWorkflowStepById,
  getWorkflowStepById,
  isLinkBranch,
} from 'store/campaign-details/selectors'
import {
  ICampaignScriptStep,
  PromptType,
  IExitAction,
  ICampaignScriptDragState,
} from 'store/campaign-scripts/reducer'
import AutoBranch from 'components/AutoBranch/AutoBranch'
import BooleanBranch from 'components/BooleanBranch/BooleanBranch'
import OpenBranch from 'components/OpenBranch/OpenBranch'
import NumberBranch from 'components/NumberBranch/NumberBranch'
import RootNode from 'components/RootNode/RootNode'
import { LinkBranch } from 'components/LinkBranch/LinkBranch'
import {
  NodeChildren,
  getNodeBranches,
} from 'components/ConnectedSubtree/ConnectedSubtreeHelpers'
import {
  ICampaignScriptStateUpdateRequest,
  ICampaignScriptStateSpliceRequest,
  ICampaignScriptStateDeleteEdgeRequest,
  ITransitionType,
  ICampaignScriptUpdateRequest,
} from 'api/request'
import {
  updateCampaignScriptStateAsync,
  spliceCampaignScriptStateAsync,
  deleteCampaignScriptStateEdgeAsync,
  linkCampaignScriptStateAsync,
  updateCampaignScriptAsync,
  editScriptNode,
} from 'store/campaign-scripts/thunks'
import * as _ from 'lodash'
import {
  ICampaignScriptCreateLinkPayload,
  IStartLinkingPayload,
  startLinking,
  campaignScriptDragState,
  stopLinking,
} from 'store/campaign-scripts/actions'
import {
  getLinkingStep,
  getLinkingTransition,
} from 'store/campaign-scripts/selectors'
import { getAttributeByIdMapping } from 'store/personalization/selectors'
import Loader from 'components/Loader/Loader'
import { isLoading } from 'store/webdata'
import { withRouter } from 'react-router'
import { IConnectedSubtreeRouteProps } from 'components/IRouteProps'

interface IConnectedSubtreeOwnProps {
  workflowStepId: string
  parentWorkflowStepId?: string
  branchName?: ITransitionType
  root?: boolean
  editable?: boolean
  campaignReview?: boolean
  useAggregateSteps?: boolean
}
interface IConnectedSubtreeDispatchProps {
  editNode?: ({
    dialogStateId,
    editing,
  }: {
    dialogStateId: string
    editing: boolean
  }) => void
  updateNode?: (request: ICampaignScriptStateUpdateRequest) => Promise<void>
  updateScript?: (request: ICampaignScriptUpdateRequest) => void
  spliceNode?: (request: ICampaignScriptStateSpliceRequest) => void
  deleteEdge?: (request: ICampaignScriptStateDeleteEdgeRequest) => void
  linkNode?: (request: ICampaignScriptCreateLinkPayload) => void
  startLinking?: (payload: IStartLinkingPayload) => void
  stopLinking?: () => void
  campaignScriptDragState?: (payload: ICampaignScriptDragState) => void
}
interface IConnectedSubtreeStateProps {
  workflowStep?: ICampaignScriptStep
  parentWorkflowStepId?: string
  branchName?: ITransitionType
  isLink: boolean
  root?: boolean
  editable?: boolean
  campaignReview?: boolean
  mappingResults?: ReturnType<typeof getAttributeByIdMapping>
}

interface ILinkingStateProps {
  linking?: boolean
  linkStep?: ICampaignScriptStep
  linkTransitionKey?: ITransitionType
}

interface IUnconnectedSubtreeState {
  newExitAction: IExitAction | null
  valueBeingSaved: string | null
}

export class UnconnectedSubtree extends React.PureComponent<
  IConnectedSubtreeStateProps &
    IConnectedSubtreeRouteProps &
    ILinkingStateProps &
    IConnectedSubtreeDispatchProps,
  IUnconnectedSubtreeState
> {
  state = {
    newExitAction: null,
    valueBeingSaved: null,
  }

  static defaultProps = {
    root: false,
  }

  fromScriptLibrary = this.props.match.path.startsWith(
    '/campaign-script-library'
  )

  isTerminal = () => {
    return (
      this.props.workflowStep &&
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      _.every(this.props.workflowStep.nextStates, x => x!.default === false)
    )
  }

  deleteEdge = (isLink: boolean) => {
    if (
      this.props.deleteEdge &&
      this.props.workflowStep &&
      this.props.branchName
    ) {
      this.props.deleteEdge({
        dialogId: this.props.workflowStep.parentDialog,
        dialogStateId: this.props.parentWorkflowStepId,
        transitionType: this.props.branchName,
        isLink,
      })
    }
  }

  deleteRoot = () => {
    const { workflowStep, updateScript } = this.props
    if (!workflowStep) {
      return
    }
    const hasDefault =
      workflowStep.nextStates['default'] &&
      (workflowStep.promptType === PromptType.auto ||
        workflowStep.promptType === PromptType.open)
    if (
      workflowStep &&
      updateScript &&
      hasDefault &&
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      typeof workflowStep.nextStates['default']!.default === 'string'
    ) {
      updateScript({
        id: workflowStep.parentDialog,
        data: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          initialState: workflowStep.nextStates['default']!.default,
        },
      })
    } else if (updateScript && this.isTerminal()) {
      updateScript({
        id: workflowStep.parentDialog,
        data: {
          initialState: null,
        },
      })
    }
  }

  deleteNode = (isLink: boolean = false) => {
    const { root } = this.props
    if (root) {
      this.deleteRoot()
    } else {
      this.deleteEdge(isLink)
    }
  }

  createLink = () => {
    if (
      this.props.linkNode &&
      this.props.linkTransitionKey &&
      this.props.linkStep &&
      this.props.workflowStep
    ) {
      const data = {
        linkTransitionKey: this.props.linkTransitionKey,
        dialogId: this.props.workflowStep.parentDialog,
        dialogStateId: this.props.linkStep.id,
        data: {
          promptType: this.props.linkStep.promptType,
          nextStates: {
            ...this.props.linkStep.nextStates,
            [this.props.linkTransitionKey]: {
              default: this.props.workflowStep.id,
            },
          },
          multipleChoices:
            this.props.linkStep.promptType === PromptType.number
              ? this.props.linkStep.multipleChoices
              : undefined,
        },
      }
      this.props.linkNode(data)
    }
  }

  handleOptOut = () => {
    const workflowStep = this.props.workflowStep
    if (!this.props.updateNode || !workflowStep) {
      return
    }
    const data: Partial<ICampaignScriptStep> = {
      promptType: workflowStep.promptType,
    }
    if (workflowStep.promptType === PromptType.number) {
      data.multipleChoices = workflowStep.multipleChoices
      data.nextStates = workflowStep.nextStates
    }
    this.props.updateNode({
      dialogId: workflowStep.parentDialog,
      dialogStateId: workflowStep.id,
      data,
    })
  }

  startLinking = (transitionType: ITransitionType) => {
    if (this.props.startLinking && this.props.workflowStep) {
      this.props.startLinking({
        linkStepId: this.props.workflowStep.id,
        linkTransitionKey: transitionType,
      })
    }
  }
  handleChangeImage = (
    imageUrl: string,
    name?: string,
    contentType?: string
  ) => {
    const workflowStep = this.props.workflowStep
    if (!this.props.updateNode || !workflowStep) {
      return
    }
    const data: Partial<ICampaignScriptStep> = {
      promptType: workflowStep.promptType,
      media: imageUrl,
      mediaName: name,
      mediaContentType: contentType,
    }
    if (workflowStep.promptType === PromptType.number) {
      data['nextStates'] = workflowStep.nextStates
      data['multipleChoices'] = workflowStep.multipleChoices
    }
    this.props.updateNode({
      dialogId: workflowStep.parentDialog,
      dialogStateId: workflowStep.id,
      data,
    })
  }

  setValueBeingSaved = (newValue: string | null) => {
    this.setState({
      valueBeingSaved: newValue,
    })
  }
  handlePromptChange = () => {
    if (this.props.workflowStep && this.props.updateNode) {
      const statesDict: {
        [key: number]: { default: string | boolean } | undefined
      } = {}
      const updatedWorkflowStepStates = Object.entries(
        this.props.workflowStep.nextStates
      )
      for (let i = 0; i < updatedWorkflowStepStates.length; i++) {
        statesDict[i + 1] = updatedWorkflowStepStates[i][1]
      }
      const value = [{ prompt: 'Yes' }, { prompt: 'No' }]

      this.props.updateNode({
        dialogId: this.props.workflowStep.parentDialog,
        dialogStateId: this.props.workflowStep.id,
        data: {
          promptType: PromptType.number,
          nextStates: statesDict,
          multipleChoices: value,
          range: { min: 1, max: value.length },
        },
      })
    }
  }
  getParentComponent = () => {
    const { workflowStep, isLink, parentWorkflowStepId } = this.props
    if (!workflowStep) {
      return null
    }
    const isTerminal = this.isTerminal()
    if (isLink) {
      return (
        parentWorkflowStepId && (
          <LinkBranch
            enableDeletion={this.props.editable && !this.props.campaignReview}
            enableJump={!this.props.campaignReview}
            editable={this.props.editable}
            workflowStep={workflowStep}
            id={parentWorkflowStepId}
            deleteEdge={this.deleteNode}
          />
        )
      )
    }
    const branches = getNodeBranches(
      workflowStep,
      this.props.editable,
      this.props.campaignReview
    )
    const children = (
      <NodeChildren
        key={workflowStep.id + '_children'}
        newExitAction={this.state.newExitAction}
        workflowStep={workflowStep}
        editable={this.props.editable}
        campaignReview={this.props.campaignReview}
      />
    )

    // All four branches accept the same props with the same values, so
    // abstracting out to an object like this to follow DRY principals
    const branchProps = {
      key: workflowStep.id,
      editable: this.props.editable,
      editing: workflowStep.editing,
      workflowStep,
      isTerminal,
      editNode: this.props.editNode,
      deleteEdge: this.deleteNode,
      spliceNode: this.props.spliceNode,
      optOut: this.handleOptOut,
      changeImage: this.handleChangeImage,
      updateNode: this.props.updateNode,
      createLink: this.createLink,
      startLinking: this.startLinking,
      stopLinking: this.props.stopLinking,
      linking: this.props.linking,
      branch: branches,
      setValueBeingSaved: this.setValueBeingSaved,
      valueBeingSaved: this.state.valueBeingSaved,
      border: this.props.campaignReview,
      handlePromptChange: this.handlePromptChange,
      campaignScriptDragState: this.props.campaignScriptDragState,
      mappingResults: this.props.mappingResults,
    }

    switch (workflowStep.promptType) {
      case PromptType.auto:
        return <AutoBranch {...branchProps}>{children}</AutoBranch>
      case PromptType.open:
        return <OpenBranch {...branchProps}>{children}</OpenBranch>
      case PromptType.boolean:
        return <BooleanBranch {...branchProps}>{children}</BooleanBranch>
      case PromptType.number:
        return <NumberBranch {...branchProps}>{children}</NumberBranch>
    }
  }

  render() {
    if (isLoading(this.props.mappingResults)) {
      return <Loader />
    }
    const { workflowStep, root } = this.props
    if (!workflowStep) {
      return null
    }
    if (root) {
      return <RootNode>{this.getParentComponent()}</RootNode>
    }
    return this.getParentComponent()
  }
}

const mapStateToProps = (
  state: IState,
  ownProps: IConnectedSubtreeOwnProps & IConnectedSubtreeRouteProps
): Pick<
  IConnectedSubtreeStateProps &
    ILinkingStateProps &
    IConnectedSubtreeDispatchProps,
  | 'editable'
  | 'campaignReview'
  | 'workflowStep'
  | 'parentWorkflowStepId'
  | 'branchName'
  | 'isLink'
  | 'linking'
  | 'linkStep'
  | 'linkTransitionKey'
  | 'root'
  | 'mappingResults'
> => ({
  editable: ownProps.editable,
  workflowStep: ownProps.useAggregateSteps
    ? getAggregateWorkflowStepById(state, ownProps)
    : getWorkflowStepById(state, ownProps),
  parentWorkflowStepId: ownProps.parentWorkflowStepId,
  branchName: ownProps.branchName,
  isLink: isLinkBranch(state, ownProps),
  linking: state.campaignScripts.ui.linking,
  linkStep: getLinkingStep(state),
  linkTransitionKey: getLinkingTransition(state),
  root: ownProps.root,
  campaignReview: ownProps.campaignReview,
  mappingResults: getAttributeByIdMapping(state),
})

const mapDispatchToProps = (
  dispatch: Dispatch
): Pick<
  IConnectedSubtreeStateProps &
    ILinkingStateProps &
    IConnectedSubtreeDispatchProps,
  | 'editNode'
  | 'updateNode'
  | 'spliceNode'
  | 'deleteEdge'
  | 'updateScript'
  | 'startLinking'
  | 'stopLinking'
  | 'linkNode'
  | 'campaignScriptDragState'
> => {
  return {
    editNode: editScriptNode(dispatch),
    updateNode: updateCampaignScriptStateAsync(dispatch),
    spliceNode: spliceCampaignScriptStateAsync(dispatch),
    deleteEdge: deleteCampaignScriptStateEdgeAsync(dispatch),
    updateScript: updateCampaignScriptAsync(dispatch),
    startLinking: (payload: IStartLinkingPayload) =>
      dispatch(startLinking(payload)),
    stopLinking: () => dispatch(stopLinking()),
    linkNode: linkCampaignScriptStateAsync(dispatch),
    campaignScriptDragState: (payload: ICampaignScriptDragState) =>
      dispatch(campaignScriptDragState(payload)),
  }
}

const ConnectedSubtree = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(UnconnectedSubtree)
)

export default ConnectedSubtree

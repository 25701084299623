import * as React from 'react'
import { isFailure, isInitial, isSuccess, isUnresolved } from 'store/webdata'
import {
  getAllContactAttributes,
  getTopLevelContactFields,
} from 'store/personalization/contactAttributes/selectors'
import { getContactFilter, updateOrCreateContactFilter } from 'api'
import { MainstayFlexTable } from 'mainstay-ui-kit/MainstayFlexTable/MainstayFlexTable'
import { MainstayFlexTableHeader } from 'mainstay-ui-kit/MainstayFlexRow/MainstayFlexRow'
import { MainstayFlexTableHeaderCol } from 'mainstay-ui-kit/MainstayFlexCol/MainstayFlexCol'
import { FilterRow } from 'components/ContactFilterBuilder/FilterRow'
import { Instructions } from 'components/ContactFilterBuilder/Instructions'
import { Button } from 'components/Button/Button'
import { InfoAlert } from 'components/Alert/Alert'
import {
  mapContactFilterToFormData,
  IContactFilterFormData,
  ContactFilterRowType,
  isGroup,
  convertFormDataToPayload,
  createFiltersValidationSchema,
  getRowsInGroup,
  isNewLevel,
  isOnlyRowInGroup,
  CombinationOperator,
} from 'components/ContactFilterBuilder/formUtils'
import {
  getAllContactAttributesAsync,
  listTopLevelContactFieldsAsync,
} from 'store/personalization/contactAttributes/thunks'
import { AxiosError, AxiosResponse } from 'typings/axios'
import { toastOnHttpError500or400 } from 'api/http'
import { Formik, Form, FieldArray, Field, FieldProps } from 'formik'
import uuid from 'uuid/v4'
import { ConfirmationModal } from 'components/Modal/Modal'
import { SubmitFilter } from 'components/ContactFilterBuilder/SaveSection'
import { CampaignRecientsPreviewContainer } from 'components/CampaignRecipientsPreview/CampaignRecipientsPreview'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import { IContactFilterRequestData } from 'api/request'
import { IContactSegmentList } from 'store/contact-segments/selectors'
import { Spinner } from 'components/Spinner/Spinner'
import Tooltip from 'components/Tooltip/Tooltip'
import { IContactFilterResponseData, IEmptyContactFilter } from 'api/response'
import { UserGroupSelector } from 'components/UserGroupSelector/UserGroupSelector'

import 'components/ContactFilterBuilder/ContactFilterBuilder.scss'
import { PERMISSIONS } from 'util/permissions/permissions'
import PermissionsGuard from 'util/permissions/PermissionsGuard'
import { useSelector, useDispatch, useFeatures } from 'util/hooks'

export interface IContactFilterProps {
  filterId?: number
  allFilters?: IContactSegmentList
  disableRecipientsPreview?: boolean
  onCancel: () => void
  onSaveAndContinue: (
    formData: IContactFilterFormData,
    isFormDirty?: boolean
  ) => void
  readOnly?: boolean
  historicalContactFilterObj?: IContactFilterResponseData
}

export interface IReadOnlyContactFilterProps extends IContactFilterProps {
  filterId?: number
  allFilters: undefined
  disabledRecipientsPreview: undefined
  onCancel: () => void
  onSaveAndContinue: () => void
  readOnly?: boolean
  historicalContactFilterObj?: IContactFilterResponseData
}

export const ContactFilterBuilder = ({
  filterId,
  allFilters,
  disableRecipientsPreview,
  onCancel,
  onSaveAndContinue,
  readOnly = false,
  historicalContactFilterObj,
}: IContactFilterProps | IReadOnlyContactFilterProps) => {
  const dispatch = useDispatch()
  const { hasFeature, FeaturesType } = useFeatures()

  const contactAttributes = useSelector(getAllContactAttributes)
  const topLevelContactFields = useSelector(getTopLevelContactFields)

  const fetchAllContactAttributes = React.useCallback(
    () => getAllContactAttributesAsync(dispatch)(),
    [dispatch]
  )
  const fetchTopLevelFields = React.useCallback(
    () => listTopLevelContactFieldsAsync(dispatch)(),
    [dispatch]
  )

  const [formData, setFormData] = React.useState<IContactFilterFormData>()
  const [confirmDeleteAction, setConfirmDeleteAction] = React.useState<
    () => void
  >()
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = React.useState(
    false
  )
  const [maxLevel, setMaxLevel] = React.useState(0)
  const [contactFilterObj, setContactFilterObj] = React.useState<
    IContactFilterRequestData | undefined
  >(undefined)
  const [showRecipientsPreview, setShowRecipientsPreview] = React.useState<
    boolean
  >(false)
  const [showFormErrors, setShowFormErrors] = React.useState(false)

  const [isFormDirty, setIsFormDirty] = React.useState(false)

  React.useEffect(() => {
    if (isInitial(contactAttributes)) {
      fetchAllContactAttributes()
    }

    if (isInitial(topLevelContactFields)) {
      fetchTopLevelFields()
    }
  }, [
    contactAttributes,
    fetchAllContactAttributes,
    fetchTopLevelFields,
    topLevelContactFields,
  ])

  const [defaultRegion, setDefaultRegion] = React.useState<string | null>(null)

  const initialEmptyFilterObj: IEmptyContactFilter = React.useMemo(() => {
    return {
      name: '',
      id: undefined,
      condition_group: {
        combination_type: CombinationOperator.ALL,
        conditions: [
          {
            comparison_type: null,
            contact_attribute: null,
            contact_field: null,
            value: null,
          },
        ],
        condition_groups: [],
      },
      region: defaultRegion || null,
      size: null,
      last_calculated: null,
    }
  }, [defaultRegion])

  React.useEffect(() => {
    if (
      isSuccess(contactAttributes) &&
      isSuccess(topLevelContactFields) &&
      readOnly
    ) {
      if (filterId && historicalContactFilterObj) {
        setFormData(
          mapContactFilterToFormData(
            historicalContactFilterObj,
            contactAttributes.data,
            topLevelContactFields.data
          )
        )
      }
    }
  }, [
    contactAttributes,
    topLevelContactFields,
    readOnly,
    historicalContactFilterObj,
    filterId,
  ])

  React.useEffect(() => {
    let _isMounted = true
    if (
      isSuccess(contactAttributes) &&
      isSuccess(topLevelContactFields) &&
      !readOnly
    ) {
      if (filterId) {
        getContactFilter(filterId)
          .then(res => {
            if (_isMounted) {
              setMaxLevel(0)
              setFormData(
                mapContactFilterToFormData(
                  res.data,
                  contactAttributes.data,
                  topLevelContactFields.data
                )
              )
            }
          })
          .catch((err: AxiosError) => {
            // TODO (Manan) - Let's handle errors better
            toastOnHttpError500or400(err)
          })
      } else if (_isMounted) {
        setMaxLevel(0)
        setFormData(
          mapContactFilterToFormData(
            initialEmptyFilterObj,
            contactAttributes.data,
            topLevelContactFields.data
          )
        )
      }
    }
    return () => {
      _isMounted = false
    }
  }, [
    contactAttributes,
    filterId,
    topLevelContactFields,
    initialEmptyFilterObj,
    readOnly,
  ])

  React.useEffect(() => {
    if (formData) {
      setContactFilterObj(convertFormDataToPayload(formData))
    }
  }, [formData])

  if (
    isUnresolved(contactAttributes) ||
    isUnresolved(topLevelContactFields) ||
    !formData
  ) {
    return <Spinner className="stroke-mainstay-dark-blue mr-2" size="md" />
  }

  if (isFailure(contactAttributes) || isFailure(topLevelContactFields)) {
    return (
      <div>
        Error loading necessary data. Please contact support@mainstay.com for
        assistance.
      </div>
    )
  }

  return (
    <>
      {!!filterId && !readOnly ? (
        <InfoAlert>
          Editing this audience may change the recipients for any scheduled
          campaigns that are using this audience
        </InfoAlert>
      ) : null}
      {!readOnly && <Instructions />}
      <Formik<IContactFilterFormData>
        enableReinitialize={true}
        initialValues={formData}
        validationSchema={createFiltersValidationSchema(allFilters)}
        onSubmit={formData => {
          if (isFormDirty) {
            updateOrCreateContactFilter(convertFormDataToPayload(formData))
              .then((res: AxiosResponse<IContactFilterResponseData>) => {
                toast('Audience successfully saved', {
                  type: 'success',
                })
                const updatedFormData = { ...formData, id: res.data.id }
                onSaveAndContinue(updatedFormData)
              })
              .catch((err: AxiosError) => {
                toastOnHttpError500or400(err)
              })
          } else {
            onSaveAndContinue(formData, false)
          }
        }}
        render={({ setFieldValue, values, errors, dirty }) => {
          setIsFormDirty(dirty)
          const { name: nameError, ...rowErrors } = errors
          // account for empty initial state of new filter
          const isInEmptyInitialState =
            values.rows[1].parameter === undefined ||
            values.rows[1].type === undefined
          const hasErrors =
            (errors && Object.keys(errors).length > 0) ||
            values.rows.length < 2 ||
            isInEmptyInitialState
          const hasRowErrors =
            (rowErrors && Object.keys(rowErrors).length > 0) ||
            values.rows.length < 2 ||
            isInEmptyInitialState
          return (
            <Form
              onChange={() => {
                if (!hasErrors) {
                  setShowFormErrors(false)
                }
              }}>
              {!readOnly && hasFeature(FeaturesType.PERMS_REGIONS_ENABLED) && (
                <Field
                  name="region"
                  render={({ form, field }: FieldProps) => {
                    return (
                      <div>
                        <div className="d-flex align-items-center">
                          <span className="mr-3">
                            User Groups are enabled for this institution. This
                            audience is restricted to:{' '}
                          </span>
                          <UserGroupSelector
                            {...field}
                            region={values.region || defaultRegion}
                            setDefaultRegion={setDefaultRegion}
                            onChange={val => {
                              form.setFieldValue('region', val.value)
                            }}
                          />
                        </div>
                        <hr />
                      </div>
                    )
                  }}
                />
              )}
              <MainstayFlexTable className="border-none">
                {!readOnly && (
                  <MainstayFlexTableHeader noBorder={true}>
                    <MainstayFlexTableHeaderCol
                      xs={1}
                      style={{ minWidth: (maxLevel + 4) * 25 }}
                    />
                    <MainstayFlexTableHeaderCol xs={3}>
                      Parameter
                    </MainstayFlexTableHeaderCol>
                    <MainstayFlexTableHeaderCol xs={2}>
                      Operator
                    </MainstayFlexTableHeaderCol>
                    <MainstayFlexTableHeaderCol xs={4}>
                      Value
                    </MainstayFlexTableHeaderCol>
                    <MainstayFlexTableHeaderCol xs={1} />
                  </MainstayFlexTableHeader>
                )}
                <FieldArray
                  name="rows"
                  render={arrayHelpers => {
                    // TODO (Manan) - Add tests for these array manipulations
                    const handleDeleteGroup = (rowIndex: number) => {
                      const rowIndicesToRemove: number[] = []
                      const currentLevel = values.rows[rowIndex].level
                      for (let j = rowIndex + 1; j < values.rows.length; j++) {
                        if (values.rows[j].level <= currentLevel) {
                          break
                        }
                        rowIndicesToRemove.push(j)
                      }
                      // Iteratively remove elements in reverse order so its index is correct
                      rowIndicesToRemove
                        .reverse()
                        .map(rowIndex => arrayHelpers.remove(rowIndex))
                    }
                    return values?.rows.map((row, i) => {
                      return (
                        <FilterRow
                          key={row.uuid || i}
                          isFirstRow={i === 0}
                          formikPrefix={`rows.${i}`}
                          row={row}
                          maxLevel={maxLevel}
                          setMaxLevel={setMaxLevel}
                          isNewLevel={isNewLevel(i, values.rows)}
                          isOnlyRuleInGroup={isOnlyRowInGroup(i, values.rows)}
                          showErrors={showFormErrors}
                          onAddRow={(parentCombination, level) => {
                            // Get first row not in group
                            let j = i + 1
                            for (; j < values.rows.length; j++) {
                              if (values.rows[j].level <= row.level) {
                                break
                              }
                            }
                            arrayHelpers.insert(j, {
                              parentCombination,
                              uuid: uuid(),
                              level,
                              type: ContactFilterRowType.ATTRIBUTE,
                            })
                          }}
                          onDeleteRow={() => {
                            if (isGroup(row)) {
                              setShowDeleteConfirmModal(true)
                              setConfirmDeleteAction(() => () => {
                                handleDeleteGroup(i)
                                arrayHelpers.remove(i)
                              })
                            } else {
                              arrayHelpers.remove(i)
                            }
                          }}
                          onDeleteGroup={callback => {
                            setConfirmDeleteAction(() => () => {
                              handleDeleteGroup(i)
                              if (callback) {
                                callback()
                              }
                            })
                            setShowDeleteConfirmModal(true)
                          }}
                          onChangeGroupType={type => {
                            const rowIds = getRowsInGroup(values.rows, i).map(
                              x => x.uuid
                            )
                            values.rows.forEach((groupRow, j) => {
                              if (
                                rowIds.includes(groupRow.uuid) &&
                                groupRow.level === row.level + 1
                              ) {
                                setFieldValue(`rows.${j}`, {
                                  ...groupRow,
                                  parentCombination: type,
                                })
                              }
                            })
                          }}
                          contactAttributes={contactAttributes?.data || []}
                          topLevelContactFields={
                            topLevelContactFields?.data || []
                          }
                          readOnly={readOnly}
                        />
                      )
                    })
                  }}
                />
              </MainstayFlexTable>
              {!readOnly && (
                <>
                  <PermissionsGuard
                    permission={PERMISSIONS.CONTACT.VIEW}
                    placement="bottom">
                    {!disableRecipientsPreview && (
                      <Tooltip
                        content="Unable to preview results until errors are fixed above."
                        isEnabled={hasRowErrors}
                        onMount={() => {
                          setShowFormErrors(hasRowErrors)
                        }}>
                        <div className="d-flex justify-content-center w-100 pb-2">
                          <Button
                            className="btn px-3 py-2 w-25"
                            onClick={() => {
                              setContactFilterObj(
                                convertFormDataToPayload({
                                  ...values,
                                  name: values.name || uuid(),
                                })
                              )
                              setShowRecipientsPreview(true)
                            }}
                            disabled={hasRowErrors}
                            outlined
                            color="secondary-teal"
                            eventAction="click"
                            eventObject="preview results"
                            eventLocation="audiences">
                            Preview Results
                          </Button>
                        </div>
                      </Tooltip>
                    )}
                  </PermissionsGuard>
                  <hr />

                  <SubmitFilter
                    showErrors={showFormErrors}
                    disableSave={hasErrors}
                    onCancel={onCancel}
                    onMouseEnter={() => hasErrors && setShowFormErrors(true)}
                  />
                </>
              )}
            </Form>
          )
        }}
      />
      <CampaignRecientsPreviewContainer
        onClose={() => setShowRecipientsPreview(false)}
        filterByFilterObj={true}
        contactFilterObj={contactFilterObj}
        hidden={!showRecipientsPreview}
      />
      <ConfirmationModal
        show={showDeleteConfirmModal}
        helpText="This will delete the selected group and any sub-conditions. Continue?"
        hideCheckbox={true}
        onClose={() => {
          setShowDeleteConfirmModal(false)
        }}
        title="Delete Group"
        onConfirm={() => {
          if (confirmDeleteAction) {
            confirmDeleteAction()
          }
          setShowDeleteConfirmModal(false)
        }}
        confirmButtonText="Delete Group"
        zIndex={3000}
      />
    </>
  )
}

import * as api from 'api'
import cls from 'classnames'
import { ActionCheckbox } from 'components/ActionCheckbox/ActionCheckbox'
import { ContainerButton } from 'components/ContainerButton/ContainerButton'
import { Pill } from 'components/Pill/Pill'
import { formatDistanceToNow } from 'date-fns'
import { isLeft } from 'fp-ts/Either'
import { MainstayFlexTableCol } from 'mainstay-ui-kit/MainstayFlexCol/MainstayFlexCol'
import { MainstayFlexTableRow } from 'mainstay-ui-kit/MainstayFlexRow/MainstayFlexRow'
import { toast } from 'mainstay-ui-kit/MainstayToast/MainstayToast'
import {
  KnowledgeReviewMark,
  KnowledgeReviewReason,
} from 'page/conversations-v2/ConversationsDetail/'
import { MarkForKnowledgeReviewModal } from 'page/conversations-v2/ConversationsDetail/MessageRow/MarkForKnowledgeReviewModal/MarkForKnowledgeReviewModal'
import { fetchContactFeedback } from 'page/knowledge-base/ContactFeedback/actions'
import {
  addToCheckboxList,
  markKnowledgeBaseItemAsReviewed,
  removeFromCheckboxList,
} from 'page/knowledge-base/KnowledgeReview/actions'
import {
  AllMessagesDrawer,
  DrawerStates,
} from 'page/knowledge-base/KnowledgeReview/AllMessagesDrawer/AllMessagesDrawer'
import 'page/knowledge-base/KnowledgeReview/KnowledgeReviewTable/KnowledgeReviewTable.scss'
import { KnowledgeBaseReviewItemsStatus } from 'page/knowledge-base/KnowledgeReview/reducer'
import { getKnowledgeBaseReviewItems } from 'page/knowledge-base/KnowledgeReview/selectors'
import React from 'react'
import { useDispatch, useSelector } from 'util/hooks'
import { Link } from 'util/routing'
import { transportToChannel } from 'store/transport'
import { ActionMenu } from 'components/ActionMenu/ActionMenu'
import { ButtonIconRound } from 'components/ButtonIconRound/ButtonIconRound'

// contact without first or last name
export const ANONYMOUS_CONTACT = 'Anonymous Contact'

export function ConversationMessageLink({
  reviewItem,
}: {
  reviewItem: api.KnowledgeBaseReviewItemShapeType
}) {
  const relativeDate = formatDistanceToNow(new Date(reviewItem.createdAt), {
    addSuffix: true,
  })
  const url = reviewItem?.transport
    ? `/conversations-v2/${reviewItem.message.contact.id}/${transportToChannel(
        reviewItem.transport
      )}?message_id=${reviewItem.message.id}`
    : `/conversations-v2/${reviewItem.message.contact.id}?message_id=${reviewItem.message.id}`
  return (
    <div className="conversation-message-relative-date pb-2">
      {relativeDate}, from{' '}
      <Link to={url} className="conversation-message-link">
        {reviewItem.message.contact.name || ANONYMOUS_CONTACT}
      </Link>
    </div>
  )
}

/**
 * View settings that determine how a review item with a given answer type renders.
 */

interface IReviewItemTypeSetting {
  botResponse: {
    // if not null, will render a pill with this text
    tag: string | null
    // whether to show a link to view the script associated with the review item (if it exists)
    showLinkToViewScript: boolean
    // whether to show a link to edit the understanding associated with the review item (if it exists)
    showLinkToEditUnderstanding: boolean
  }
  matchedUnderstanding: {
    // whether to show the associated understanding (if it exists)
    showUnderstanding: boolean
  }
}

// view settings for all review item answer types
const reviewItemTypeSettings: {
  [type in api.KnowledgeReviewAnswerType]: IReviewItemTypeSetting
} = {
  [api.KnowledgeReviewAnswerType.Default]: {
    botResponse: {
      tag: null,
      showLinkToViewScript: true,
      showLinkToEditUnderstanding: true,
    },
    matchedUnderstanding: {
      showUnderstanding: true,
    },
  },
  [api.KnowledgeReviewAnswerType.UserResponse]: {
    botResponse: {
      tag: 'User Response',
      showLinkToViewScript: false,
      showLinkToEditUnderstanding: false,
    },
    matchedUnderstanding: {
      showUnderstanding: false,
    },
  },
  [api.KnowledgeReviewAnswerType.MainstayDefaultScript]: {
    botResponse: {
      tag: 'Mainstay Default Script',
      showLinkToViewScript: false,
      showLinkToEditUnderstanding: false,
    },
    matchedUnderstanding: {
      showUnderstanding: true,
    },
  },
  [api.KnowledgeReviewAnswerType.HistoricalAnswer]: {
    botResponse: {
      tag: 'Historical Answer',
      showLinkToViewScript: true,
      showLinkToEditUnderstanding: true,
    },
    matchedUnderstanding: {
      showUnderstanding: true,
    },
  },
}

export default function KnowledgeReviewTableRow({
  item: reviewItem,
  fetchData,
  fetchCounts,
  isAllMessagesTab,
  reason,
  onCreateNewUnderstanding,
  onClick,
}: {
  readonly item: api.KnowledgeBaseReviewItemShapeType
  readonly fetchData: () => void
  readonly fetchCounts: () => void
  readonly isAllMessagesTab: boolean
  readonly reason?: KnowledgeReviewReason
  readonly onCreateNewUnderstanding: (createdUnderstandingId: string) => void
  readonly onClick: (
    selectedKnowledgeReviewItem: api.KnowledgeBaseReviewItemShapeType
  ) => void
}) {
  const dispatch = useDispatch()

  const {
    markAsReviewedStatus,
    data: { items },
    checkboxList: { all, selectedIds, deselectedIds },
  } = useSelector(getKnowledgeBaseReviewItems)

  const [checked, setChecked] = React.useState(reviewItem.reviewed)
  const [showDrawer, setShowDrawer] = React.useState<
    | {
        drawerState: DrawerStates
        editUnderstandingId?: string
      }
    | undefined
  >(undefined) // undefined = hide drawer, value = show drawer
  const [showKBReviewModal, setKBReviewModal] = React.useState(false)

  const kbMarkAsReview = React.useCallback(
    async ({
      markedAsReviewed,
      createdUnderstandingId,
    }: {
      readonly markedAsReviewed: boolean
      createdUnderstandingId?: string
    }) => {
      const toastLabel = !markedAsReviewed
        ? 'Message sent back for Review'
        : 'Marked message as reviewed'
      setChecked(prev => !prev)

      dispatch(markKnowledgeBaseItemAsReviewed.request())
      const res = await api.knowledgeBaseItemMarkAsReviewed({
        id: reviewItem.message.id,
        reviewed: markedAsReviewed,
      })
      if (isLeft(res)) {
        dispatch(markKnowledgeBaseItemAsReviewed.failure())
        setChecked(prev => !prev)
        toast.error('Failed to mark message as reviewed')
        return false
      }

      if (createdUnderstandingId) {
        onCreateNewUnderstanding(createdUnderstandingId)
      }

      const onUndoReviewClick = async () => {
        if (markAsReviewedStatus === KnowledgeBaseReviewItemsStatus.loading) {
          return
        }
        toast.dismiss()
        await kbMarkAsReview({ markedAsReviewed: !markedAsReviewed })
        // Only if user hasn't just created a new Understanding, refetch data to
        // hydrate the table/sidebar counts with only non-reviewed messages.
        // Otherwise, the table behind the drawer flashes when changing from
        // Creation to Edit mode when performing a review.
        if (!createdUnderstandingId) {
          fetchData()
          fetchCounts()
        }
      }

      toast.success(toastLabel, {
        link: {
          text: 'Undo',
          onClick: () => onUndoReviewClick(),
        },
      })

      dispatch(markKnowledgeBaseItemAsReviewed.success())
      // Only if user hasn't just created a new Understanding, refetch data to
      // hydrate the table/sidebar counts with only non-reviewed messages.
      // Otherwise, the table behind the drawer flashes when changing from
      // Creation to Edit mode when undoing a review.
      if (!createdUnderstandingId) {
        fetchData()
        fetchCounts()
      }

      return true
    },
    [
      dispatch,
      setChecked,
      reviewItem.message.id,
      onCreateNewUnderstanding,
      markAsReviewedStatus,
      fetchData,
      fetchCounts,
    ]
  )

  const onMarkAsReviewedClick = async () => {
    if (markAsReviewedStatus === KnowledgeBaseReviewItemsStatus.loading) {
      return
    }
    await kbMarkAsReview({ markedAsReviewed: !checked })
  }

  const onApplyKnowledgeReviewMark = async (
    mark: KnowledgeReviewMark | null
  ) => {
    dispatch(fetchContactFeedback.request())

    if (!mark) {
      return
    }
    const res = await api.conversationsV2MarkForKnowledgeReview({
      messageId: reviewItem.message.id,
      mark,
    })

    if (isLeft(res)) {
      toast.error('Failed to mark message for knowledge review')
      return
    }

    toast.success('Successfully marked message for knowledge review.')
    setKBReviewModal(prev => !prev)
    fetchCounts()
    fetchData()
  }

  const responseSettings = reviewItemTypeSettings[reviewItem.answerType]

  const renderPopover = () => (
    <div className="hover-show-child-invis pl-4 d-flex flex-column align-items-center justify-content-center h-100">
      <ActionMenu
        popoverPlacement="bottom-end"
        stopPropagation={true}
        menuItems={
          isAllMessagesTab
            ? [
                {
                  label: 'Add to Knowledge Maintenance',
                  onSelect: () => setKBReviewModal(prev => !prev),
                },
                {
                  label: 'View Match Details',
                  onSelect: () =>
                    setShowDrawer({
                      drawerState: DrawerStates.matched_understanding,
                    }),
                },
              ]
            : [
                ...(reason === KnowledgeReviewReason.AnswerIncorrect
                  ? [
                      {
                        label: 'Find Different Understanding',
                        onSelect: () => null,
                      },
                    ]
                  : []),
                {
                  label: 'Mark as reviewed',
                  onSelect: onMarkAsReviewedClick,
                },
              ]
        }
      />
    </div>
  )

  const renderAnswer = () => {
    const hasScriptEditLink =
      responseSettings.botResponse.showLinkToViewScript &&
      reviewItem.dialogId &&
      reviewItem.dialogId !== 'aiDialog'

    if (hasScriptEditLink) {
      return (
        <div className="mb-2">
          <Link to={`/campaign-scripts/${reviewItem.dialogId}/view`}>
            Interactive Response - View Script
          </Link>
          <div>{reviewItem.answer || '-'}</div>
        </div>
      )
    }

    return reviewItem.answer || '-'
  }

  const renderEditAnswer = () =>
    responseSettings.botResponse.showLinkToEditUnderstanding &&
    reviewItem.understandingId && (
      <div>
        <ContainerButton
          onClick={() =>
            setShowDrawer({
              drawerState: DrawerStates.edit_understanding,
              editUnderstandingId: reviewItem.understandingId || undefined,
            })
          }
          className="pointer text-secondary-teal">
          Edit Answer
        </ContainerButton>
      </div>
    )

  const renderInfoPill = () =>
    responseSettings.botResponse.tag && (
      <div className="d-flex">
        <Pill
          className="mb-1"
          text={responseSettings.botResponse.tag}
          color="mainstay-dark-blue-10"
          textColor="mainstay-dark-blue"
        />
      </div>
    )

  const renderUnderstanding = () => (
    <div>
      {(responseSettings.matchedUnderstanding.showUnderstanding &&
        reviewItem.understanding) ||
        '-'}
    </div>
  )

  const renderFindAnUnderstanding = (text: string) => (
    <ContainerButton
      onClick={() => {
        onClick(reviewItem)
        setShowDrawer({
          drawerState: DrawerStates.search_understandings,
        })
      }}
      className="pointer text-secondary-teal">
      {text}
    </ContainerButton>
  )

  const renderTableRow = () => {
    switch (reason) {
      case KnowledgeReviewReason.AnswerIncorrect:
        return (
          <>
            <MainstayFlexTableCol className="flex-column" xs={4}>
              {renderInfoPill()}
              {renderAnswer()}
              {renderEditAnswer()}
            </MainstayFlexTableCol>
            <MainstayFlexTableCol className="flex-column" xs={3}>
              <span>{reviewItem.note || '-'}</span>
            </MainstayFlexTableCol>
            <MainstayFlexTableCol xs={2} className="flex-column">
              <div className="d-flex h-100 justify-content-end align-items-end">
                {renderPopover()}
              </div>
            </MainstayFlexTableCol>
          </>
        )
      case KnowledgeReviewReason.BotCouldNotAnswer:
        return (
          <>
            <MainstayFlexTableCol className="flex-column" xs={5}>
              <div className="d-flex pt-2">
                {renderFindAnUnderstanding('Find an Understanding')}
              </div>
            </MainstayFlexTableCol>
            <MainstayFlexTableCol className="flex-column" xs={3}>
              <span>{reviewItem.note || '-'}</span>
            </MainstayFlexTableCol>
            <MainstayFlexTableCol xs={2} className="flex-column">
              <div className="d-flex h-100 justify-content-end align-items-start show-on-hover z-index-10">
                <ButtonIconRound
                  className="text-muted hover-text-new-ui-danger"
                  onClick={onMarkAsReviewedClick}
                  icon="delete"
                  tooltipText="Mark as reviewed"
                  tooltipPlacement="top"
                />
              </div>
            </MainstayFlexTableCol>
          </>
        )
      default:
        return (
          <>
            <MainstayFlexTableCol xs={4}>
              {renderUnderstanding()}
            </MainstayFlexTableCol>
            <MainstayFlexTableCol xs={4}>
              {renderInfoPill()}
              {renderAnswer()}
              {renderEditAnswer()}
            </MainstayFlexTableCol>
            <MainstayFlexTableCol xs={1} className="flex-column">
              <div className="d-flex h-100 justify-content-end align-items-start">
                {renderPopover()}
              </div>
            </MainstayFlexTableCol>
          </>
        )
    }
  }

  return (
    <MainstayFlexTableRow className="hover-show-parent hover-bg-mainstay-dark-blue-05">
      <MainstayFlexTableCol>
        <div className="d-flex">
          <div
            className={cls('pr-1', {
              'hover-show-child-invis': !selectedIds.length,
            })}>
            <ActionCheckbox
              id={reviewItem.message.id}
              show
              all={all}
              deselectedIds={deselectedIds}
              selectedIds={selectedIds}
              itemIds={items.map(i => i.message.id)}
              onAddToCheckboxList={ids => {
                dispatch(addToCheckboxList(ids))
              }}
              onRemoveFromCheckboxList={id => {
                dispatch(removeFromCheckboxList(id))
              }}
            />
          </div>
          <div className="d-flex flex-column">
            <span className="word-break-break-word">
              “{reviewItem.message.body}”
            </span>
            <ConversationMessageLink reviewItem={reviewItem} />
          </div>
        </div>
      </MainstayFlexTableCol>
      {renderTableRow()}
      {showDrawer && (
        <AllMessagesDrawer
          open={showDrawer !== undefined}
          reviewItem={reviewItem}
          onClose={() => setShowDrawer(undefined)}
          markAsReviewed={createdUnderstandingId => {
            return kbMarkAsReview({
              markedAsReviewed: true,
              createdUnderstandingId,
            })
          }}
          initialDrawerState={showDrawer.drawerState}
          initialEditUnderstandingId={showDrawer.editUnderstandingId}
        />
      )}
      {showKBReviewModal && (
        <MarkForKnowledgeReviewModal
          loading={false}
          modalVisible={showKBReviewModal}
          toggleModal={() => setKBReviewModal(prev => !prev)}
          applyKnowledgeReviewMark={mark => onApplyKnowledgeReviewMark(mark)}
          knowledgeReviewMark={null}
        />
      )}
    </MainstayFlexTableRow>
  )
}

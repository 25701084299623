import AdmithubOnly from 'components/AdmithubOnly/AdmithubOnly'
import { Spinner } from 'components/Spinner/Spinner'
import { isLeft } from 'fp-ts/lib/Either'
import NoMatch from 'page/NoMatch'
import { InternalToolsPageContainer } from 'page/internal-tools/InternalToolsPageContainer'
import { useCallback, useEffect, useState } from 'react'

import { MainstayModal } from 'components/Modal/Modal'
import { Pill } from 'components/Pill/Pill'
import {
  MainstayFlexTableCol,
  MainstayFlexTableHeaderCol,
} from 'mainstay-ui-kit/MainstayFlexCol/MainstayFlexCol'
import {
  MainstayFlexTableHeader,
  MainstayFlexTableRow,
} from 'mainstay-ui-kit/MainstayFlexRow/MainstayFlexRow'
import { MainstayFlexTable } from 'mainstay-ui-kit/MainstayFlexTable/MainstayFlexTable'
import moment from 'moment'
import {
  getColorForScrapeStatus,
  getKnowledgeSourceTitle,
} from 'page/knowledge-base/KnowledgeSource/KnowledgeSourcesTable'
import { useInterval } from 'util/hooks'
import { GenAIScrapeAttemptShapeType, generativeTextService } from 'api'
import { InfoIcon } from 'mainstay-ui-kit/MainstayToast/Icons/InfoIcon/InfoIcon'

const FETCH_SCRAPE_ATTEMPTS_DELAY_MS = 5000

const ScrapeAttempts = () => {
  const [initialLoad, setInitialLoad] = useState(true)
  const [scrapeAttempts, setScrapeAttempts] = useState<
    GenAIScrapeAttemptShapeType[] | 'loading' | 'error'
  >('loading')

  const fetchScrapeAttempts = useCallback(async (showLoadingState = true) => {
    if (showLoadingState) {
      setScrapeAttempts('loading')
    }

    const res = await generativeTextService.scrape.scrapeAttempts.list()
    if (isLeft(res)) {
      setScrapeAttempts('error')
      return
    }

    setScrapeAttempts(res.right)
  }, [])

  useEffect(() => {
    const _fetchScrapeAttempts = async () => {
      await fetchScrapeAttempts()
      setInitialLoad(false)
    }
    _fetchScrapeAttempts()
  }, [fetchScrapeAttempts])

  useInterval(() => {
    if (!initialLoad && document.hasFocus()) {
      fetchScrapeAttempts(false)
    }
  }, FETCH_SCRAPE_ATTEMPTS_DELAY_MS)

  return (
    <section>
      <div className="py-2">
        {scrapeAttempts === 'loading' ? (
          <div className="d-flex flex-row align-center">
            <Spinner size="sm" className="mr-2 stroke-mainstay-dark-blue" />
            Loading
          </div>
        ) : scrapeAttempts === 'error' ? (
          <p>Failed to load scrape attempts</p>
        ) : (
          <MainstayFlexTable className="mt-3">
            <MainstayFlexTableHeader>
              <MainstayFlexTableHeaderCol xs={7}>
                Knowledge Source
              </MainstayFlexTableHeaderCol>

              <MainstayFlexTableHeaderCol xs={2}>
                Status
              </MainstayFlexTableHeaderCol>

              <MainstayFlexTableHeaderCol xs={2}>
                Status Modified At
              </MainstayFlexTableHeaderCol>

              <MainstayFlexTableHeaderCol xs={1} />
            </MainstayFlexTableHeader>

            {scrapeAttempts.map(scrapeAttempt => (
              <ScrapeAttemptRow
                key={`knowledge-base-scraping-scrape-attempt-${scrapeAttempt.id}`}
                scrapeAttempt={scrapeAttempt}
              />
            ))}
          </MainstayFlexTable>
        )}
      </div>
    </section>
  )
}

const ScrapeAttemptRow = ({
  scrapeAttempt,
}: {
  readonly scrapeAttempt: GenAIScrapeAttemptShapeType
}) => {
  const [showStatusMessage, setShowStatusMessage] = useState(false)

  return (
    <>
      <MainstayModal
        hideFooter
        show={showStatusMessage}
        text="Status Message"
        onClose={() => setShowStatusMessage(!showStatusMessage)}>
        <div className="pb-4">
          <code>{scrapeAttempt.status_message || 'No status message'}</code>
        </div>
      </MainstayModal>
      <MainstayFlexTableRow>
        <MainstayFlexTableCol xs={7}>
          {getKnowledgeSourceTitle(scrapeAttempt.knowledge_source)}
        </MainstayFlexTableCol>

        <MainstayFlexTableCol xs={2}>
          <div className="d-flex">
            <Pill
              className="w-content"
              text={scrapeAttempt.status}
              color={getColorForScrapeStatus(scrapeAttempt.status)}
            />
          </div>
        </MainstayFlexTableCol>

        <MainstayFlexTableCol xs={2}>
          {moment(scrapeAttempt.modified).format('YYYY-MM-DD hh:mm A')}
        </MainstayFlexTableCol>

        <MainstayFlexTableCol xs={1}>
          <span
            className="cursor-pointer"
            onClick={() => setShowStatusMessage(true)}>
            <InfoIcon fill="#5F779A" />
          </span>
        </MainstayFlexTableCol>
      </MainstayFlexTableRow>
    </>
  )
}

export const InternalScrapeAttempts = () => {
  return (
    <AdmithubOnly fallback={<NoMatch />}>
      <InternalToolsPageContainer>
        <div className="px-4 pt-4 text-mainstay-dark-blue-80 generative-ai-transactions-container">
          <h2>Scrape Attempts</h2>
          <ScrapeAttempts />
        </div>
      </InternalToolsPageContainer>
    </AdmithubOnly>
  )
}

import * as React from 'react'
import classnames from 'classnames'
import { CloseableImage } from 'components/CloseableImage/CloseableImage'
import { IVCard } from 'store/campaign-scripts/reducer'
import { VCard } from 'components/VCard/VCard'
interface IMediaNodeProps {
  readonly className?: string
  readonly url: string
  readonly onRemove?: () => void
  readonly vCard?: IVCard
}

export default class MediaNode extends React.PureComponent<IMediaNodeProps> {
  render() {
    if (this.props.vCard) {
      return <VCard {...this.props.vCard} showBorder={true} />
    }

    return (
      <div className={classnames('node', this.props.className)}>
        <CloseableImage
          image={this.props.url}
          visible
          onClose={this.props.onRemove}
        />
      </div>
    )
  }
}
